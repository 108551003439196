/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/** PAGE HEADER **/
.boxHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-family: $familyDefault, $fallbackfont;

    & .boxStep {
        margin-bottom: 12px; 

        & .stepName {
            padding: 7px 10px;
            border-radius: 10px;
            text-align: center;
            font-size: 14px;
            color: rgba($blue-dark, .65);
            font-weight: 500;
            cursor: pointer;

            &.active {
                background-color: rgba($blue-dark, .15);
                color: $blue-dark;
                font-weight: 600;
                cursor: default;
                padding: 7px 25px;
            }
        }

        & ul {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: rgba($blue-dark, .3);

            & li {
                list-style: none;
                padding: 0 10px;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    & .boxDone {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: rgba($dark, .6);
        padding: 0 15px;

        & .progressWrap {
            margin: 0 10px;

            & .progressBar {
                height: 10px;
                width: 130px;
                border-radius: 10px;
                background-color: rgba($dark, .04);
                position: relative;
                overflow: hidden;

                & .progressReal {
                    width: 0%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 10px;
                    background: linear-gradient(270deg, rgba(1, 183, 174, 0.84) 27.38%, rgba(45, 51, 141, 0.84) 154.29%);
                    transition: all 350ms ease-in-out;
                }

            }
        }

    }
}

/** PAGE FOOTER **/
.boxFooter {
    padding: 25px 0;
    padding-bottom: 10px;
    position: absolute;
    bottom: 10px;
    &.profileFoot {
        & .button {
            min-width: 80px;
        }
    }
    & .button {
        min-width: 120px;
    }

    & .goBack {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-decoration: none !important;
        padding-bottom: 12px;
        cursor: pointer;

        & b {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 38px;
            line-height: 12px;
        }
    }
}

/** POFILE PICTURE **/
.profilePic {
    display: flex;
    align-items: center;

    & .picCircle {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: $primary;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 20px rgba($primary, .2);
        }

        & input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        & .showImg {
            display: inline-block;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
        }
        & b {
            color: $light;
            font-size: 28px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        & img {
            max-width: 100%;
            object-fit: cover;
            display: block;
        }
    }

    & p {
        font-size: 14px;
        font-weight: 700;
        color: $primary;
        padding: 0 20px;
    }

    & span {
        font-size: 14px;
        font-weight: 400;
        color: $dark-400;
        padding: 0 20px;
        display: block;
    }
}
.cursor-pointer { cursor: pointer; }
/** POFILE DETAILS **/
.presenceList {

    & .presenceItem {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;

        & .presenceName {
            font-size: 15px;
            font-family: $familyDefault, $fallbackfont;
            color: $dark-400;
            font-weight: 500;
            width: 20%;
        }

        & .presenceIcon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            background-color: rgba($dark, .04);
            vertical-align: middle;
            margin-right: 15px;
        }

        & .presenceUrl {
            max-width: 475px;
            width: 60%;
        }

        & .presenceView {
            width: 20%;
        }
    }
}

.rateList {
    & li {
        list-style: none;
    }
}

.rateList+small {
    font-size: 12px;
    color: $dark;
    line-height: 18px;
    display: block;
}

/** RATE **/
.rateLevel {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px 0;
}



.anotherService {
    display: flex;
    margin: 0 -40px;

    &-col {
        padding: 0 40px;
        width: 100%;
    }

    & .serviceFee {
        font-size: 14px;
        color: $dark;
        line-height: normal;
        position: relative;
        margin-top: 50px;
        max-width: 350px;

        & b {
            font-weight: 700;
            margin-bottom: 5px;
            display: block;
        }

        & a {
            color: $primary;
            text-decoration: none;
            font-weight: 700;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
}

.sampleText {
    font-size: 14px;
    color: $dark-400;
}

.sampleCond {
    font-size: 12px;
    color: $dark-400;
    padding: 15px 0;

    & span {
        padding: 0 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    & b {
        color: $dark;
    }
}

.sampleUpload {
    display: flex;
    align-items: center;

    &-file {
        width: 168px;

        & .imgWrap {
            margin: 0;
        }
    }

    &-more {
        padding-left: 25px;

        & a {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: rgba($primary, .1);
            color: $primary !important;
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
                opacity: .85;
            }
        }

        & small {
            display: inline-flex;
            font-size: 11px;
            color: $dark;
            font-style: italic;
            align-items: center;

            & span {
                background-color: $primary;
                color: $light !important;
                width: 18px;
                height: 18px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 10px;
                padding: 0;
                cursor: pointer;
                text-decoration: none;
                margin-right: 5px;
            }
        }
    }
}

.serviceRates {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -40px;

    &-col {
        padding: 0 40px;
        width: 50%;
    }
}

.imageUpload {
    display: flex;
    align-items: center;

    &-row {
        display: flex;
        align-items: center;
    }

    &-file {
        width: 168px;

        & .imgWrap {
            margin: 0;
        }
    }

    &-updated {
        font-weight: 700;
        color: $primary;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        margin-left: 15px;

        &:hover {
            color: $primary;
        }
    }

    &-more {
        padding-left: 25px;

        & a {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: rgba($primary, .1);
            color: $primary !important;
            font-size: 14px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
                opacity: .85;
            }
        }

        & small {
            display: inline-flex;
            font-size: 11px;
            color: $dark;
            font-style: italic;
            align-items: center;

            & span {
                background-color: $primary;
                color: $light !important;
                width: 18px;
                height: 18px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 10px;
                padding: 0;
                cursor: pointer;
                text-decoration: none;
                margin-right: 5px;
            }
        }
    }
}

/****/

.titleText {
    position: relative;

    & .showOpt {
        position: absolute;
        right: 6%;
        top: 0;
        font-size: 12px;
        font-weight: 400;
    }
}

.founderMsg {
    max-width: 410px;
    width: 100%;
    display: inline-table;

    &-video {
        max-width: 240px;
        margin: 15px auto;
    }

    &-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        color: $primary;
    }

    & .videoWrap {
        border-radius: 2px;
        box-shadow: 0 0 35px rgba($dark, 0.09);
        background-color: $dark;
        position: relative;
        overflow: hidden;
        transition: all 800ms ease-in-out;


        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($dark, .75);
        }

        & .videoButton {
            width: 24px;
            height: 24px;
            // background-color: $light;
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            display: block;
            cursor: pointer;
            z-index: 1;

            & img {
                width: 24px;
                height: 24px;
                display: block;
            }

            &:hover {
                opacity: .85;
            }
        }


        & video {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }


}

/** PROFILE PREVIEW **/
.previewTitle {
    padding: 20px 0;
    background-color: rgba($dark-900, .06);
    margin-top: 22px;

    & h1 {
        font-size: 30px;
        color: $dark;
        font-weight: 700;
        margin-bottom: 5px;
    }

    & p {
        font-size: 22px;
        line-height: normal;
    }
}

.previewContent {
    background-color: $light;
    padding-top: 30px;
    min-height: calc(100vh - 92px);
    & .simpleTabs {
        padding-left: 10px;
    }

    & .boxCover {
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 10px;
        padding-right: 10px;
        background: none;
        box-shadow: none;
        margin-top: 0;

        &.addStyle {
            background: $light;
            box-shadow: 0px 0px 14px rgba($dark, 0.09);
            border-radius: 20px;
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 30px !important;
            & .boxTitle {
                color: $blue-dark;
            }
        }
        &.referralBox {
            border-radius: 20px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 20px;
            padding-bottom: 15px; 
            border: 0.5px solid rgba(45, 51, 141, 0.30);
            background: #FFF;
            box-shadow: 0px 0px 14px rgba($dark, 0.09);
            min-height: 102px;
            & .inrPrice {
                font-weight: 600;
            }
        }
        

        &:first-child {
            margin-top: 0;
        }
    }
}

.previewProfile {
    padding: 12px;
    background-color: $light;
    box-shadow: 0 0 18px rgba($dark, .07);
    border-radius: 0 30px 0 30px;
    display: flex;
    align-items: center;
    clear: both;
    margin-bottom: 30px;

    &-img {
        max-width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #f1f1f1;
        width: 100%;
        overflow: hidden;

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: 0 auto;
        }
    }

    &-info {
        padding: 0 10px;
        padding-right: 0;
        width: 80%;

        & h2 {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            font-weight: 600;
            color: $dark;
            margin: 0;
            text-transform: capitalize;
        }

        & small {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            font-size: 14px;
            line-height: 16px;
            color: $blue-dark;
            font-weight: 400;
            padding: 3px 6px;
            border-radius: 5px;
        }

        & h2+small {
            margin-left: 10px;
            border: 1px solid $blue-dark;
            background-color: rgba($blue-dark, 0);
        }

        & p {
            font-size: 16px;
            font-weight: 500;
            color: $dark;
            padding: 0 10px;
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                width: 1px;
                height: 14px;
                background: #A7A7A7;
            }
 
            & .updateLink {
                text-decoration: none;
                color: $primary;
                font-weight: 700;
                margin-left: 20px;
            }

            &:first-child {
                padding-left: 0;
                &::before{
                    display: none;
                }
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            & b {
                margin-right: 5px;
                vertical-align: middle;
                display: inline-block;
                margin-bottom: -1px;
            }

        }
    }

    &-img+&-info {
        padding-left: 20px;
        min-width: 600px;
    } 

    &-btn {
        //min-width: 346px;
        text-align: right;
        width: 100%;
    }

    & .reportBox {
      
        padding-left: 10px;
        padding-right: 10px;

        & ul {
            & li {
                padding: 0 12px;
            }
        }
    }
    &.draftProfileHeader {
        & .previewProfile-info{
            & p {
                color: #2D338D;
                position: relative;
                &:first-child {
                    &::before{
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 3px;
                        background: #97979B;
                        width: .5px;
                        height: 20px;
                    }
                }
            }
        }
        & .button-primary {
            min-width: 150px;
        }
    }
}


/** REVIEW **/
.reviewList {
    padding-top: 10px;

    & li {
        list-style: none;
        padding: 15px 10px;
        border-bottom: 1px solid rgba($dark, .08);

        &:last-child {
            border-bottom: none;
        }
    }
}


.reviewCard {
    padding: 5px 0;
    padding-right: 120px;
    position: relative;

    & .flagMark {
        position: absolute;
        right: 52px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        text-decoration: none;

    }

    &-intro {
        display: flex;
    }

    &-img {
        width: 26px;
        height: 26px;
        display: block;
        border-radius: 50%;
        overflow: hidden;
    }

    &-info {
        padding: 0 10px;

        & .info-name {
            font-size: 12px;
            color: $dark-700;
            display: flex;
            align-items: center;

            & p {
                font-weight: 700;
                color: $primary;
                padding-right: 5px;
            }

            & small {
                font-weight: 400;
                color: $dark-400;
                padding-left: 5px;
                font-size: 12px;
            }
        }

        & .info-rating {
            display: flex;
            align-items: center;
            font-size: 0;
            padding-top: 0;

            & img {
                width: 12px;
                display: block;
            }
        }
    }

    &-text {
        padding-top: 12px;

        & p {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }
    }
}


.otpInfo {
    width: 100%;
    text-align: center;
    padding: 5px 0;

    & p {
        font-size: 14px;
        font-weight: 500;
        color: $dark;
    }

    & small {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: $dark-900;
        padding-top: 5px;

        & a {
            font-weight: 700;
            color: $primary !important;
            text-decoration: none;
        }
    }
}


.simpleText {
    font-size: 14px;
    color: $dark;
}

.progress-container {
    font-size: 14px;
    font-weight: 500;
    color: rgba($blue-dark, 1);

    & .progress {
        width: 130px;
        height: 10px;
        background-color: $dark-ee;
        border-radius: 10px;

        &-bar {
            border-radius: 10px;
            background: linear-gradient(270deg,
                    rgba(255, 176, 27, 0.84) -2.89%,
                    rgba(227, 232, 123, 0.84) 100%);
        }
    }
}

.multiselectBox {
    & .ant-collapse-header-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #2C3394;
    }
}
.statusBox-body ul li {
    .reviewerUser { 
        & a {
            position: relative;
            padding-right: 10px;
            display: inline-block;
            &:last-child{
                &::before{ display: none;}
            }
            &::before{
                content: ',';
                position: absolute;
                right: 4px;
                top: 0px;
            }
        }
    }
}
 