.badgeBox {
    background-color: rgba($blue-dark, .08);
    display: inline-flex;
    align-items: center;
    padding: 2px 12px;
    border-radius: 5px;
    font-size: 14px;
    color: $blue-dark;
    font-weight: 400;
}

.labelBadge {
    display: inline-flex; 
    padding: 2px 6px;
    font-size: 12px;
    color: #BF1E2E;
    border-radius: 5px;
    background-color: rgba($blue-dark, 0);
    font-weight: 400;
    margin: 0 5px;
    line-height: 14px;
    border: 1px solid #BF1E2E;
}

.tagBadge {
    // display: inline-flex;
    padding: 3px 6px;
    font-size: 14px;
    line-height: 16px;
    color: $blue-dark;
    border-radius: 5px;
    background-color: rgba($blue-dark, 0);
    font-weight: 400;
    margin: 2px;
    border: 1px solid $blue-dark; 
    white-space: nowrap;
    max-width: 256px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}


.textBadge {
    display: inline-flex;
    padding: 3px 2px;
    font-size: 14px;
    line-height: 16px;
    color: $highlight;
    font-weight: 400;
    margin: 2px;
}