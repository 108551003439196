/** PLACEHOLDER **/
::-webkit-input-placeholder {
  font-weight: 400;
  color: $dark-600;
  font-size: 14px;
}

:-ms-input-placeholder {
  font-weight: 400;
  color: $dark-600;
  font-size: 14px;
}

::placeholder {
  font-weight: 400;
  color: $dark-600; 
  font-size: 14px;
}

/** FORM ELEMENT **/
.labelSubHeading {
  font-size: 18px;
  display: block;
  color: $dark-400;
  font-weight: 500;
 
  & span {
    font-size: 86%;
    font-style: italic;
    opacity: .65;
    letter-spacing: .5px;
    font-weight: 400;
  }

  &:empty {
    margin-bottom: 0;
  }
}

.labelSubHeading+small {
  color: $dark-400;
  display: block;
}

.hr-line+.labelSubHeading {
  margin-bottom: 25px;
}

.labelForm {
  font-size: 16px;
  display: block;
  color: $dark-200;
  margin-bottom: 4px;
  padding: 0 15px;
  font-weight: 600;
  line-height: normal;

  & span {
    font-size: 96%;
    font-style: italic;
    opacity: .65;
    letter-spacing: .5px;
  }

  & sup {
    color: $red;
  }

  & .labelBadge {
    top: -2px;
    position: relative;
  }
}

input.fieldForm,
select.fieldForm,
textarea.fieldForm,
p.fieldForm,
a.fieldForm,
p.textForm,
ul.textForm {
  height: 36px;
  border-radius: 10px;
  background-color: $light;
  padding: 6px 15px;
  font-size: 16px;
  color: $blue-dark;
  width: 100%;
  font-weight: 600;
  border: 1px solid rgba($dark-900, .5);
  text-decoration: none;

  &:disabled,
  &[disabled] {
    background-color: rgba($blue-dark, .04);
    border-color: rgba($blue-dark, 0) !important;
    color: $blue-dark !important;
  }
} 

p.fieldForm,
p.textForm,
a.fieldForm,
ul.textForm {
  height: auto;
  line-height: 22px;
  background-color: rgba($blue-dark, .04);
  border-color: rgba($blue-dark, 0);
}

p.textForm {
  color: $dark-100;
  font-weight: 400;
  line-height: 24px;
}

ul.textForm {
  color: $dark-100;
  font-weight: 400;
  line-height: 28px;
  padding-left: 35px;

  & li {
    list-style: disc;
    padding: 2px 0;
  }
}

a.fieldForm {
  color: $primary;
  cursor: pointer;
  display: block;
}

p.textarea,
textarea.fieldForm {
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba($dark-900, .06);
  resize: none;
  color: #000000;
  font-weight: 500;
}

p.textarea {
  min-height: 100px;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fieldWrap {
  position: relative;
}

.fieldCover {
  position: relative;

  /* & b {
    position: absolute;
    left: 14px;
    top: 10px;
    color: $dark;
  }

  & .fieldForm {
    padding-left: 40px;
  }*/
}

.fieldIcon {
  position: relative;

  &_pre,
  &_post {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $dark-900;
    width: 16px;
    height: 16px;

    & b {
      display: block;
    }
  }

  &_pre {
    left: 12px;
  }

  &_post {
    right: 12px;
  }

  &_pre+.fieldForm {
    padding-left: 35px;
  }

}


.fieldMore {
  padding: 10px 0;

  & a {
    font-weight: 700;
    font-size: 14px;
    color: $primary !important;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      opacity: .85;
      text-decoration: underline !important;
    }
    & .removeSection{
      color: #97979b;
      font-weight: 700; 
    }
  }
}

.fieldNote {
  font-size: 11px;
  display: block;
  color: $dark-400;
  font-style: italic;
  padding: 0 15px;
  margin-bottom: 15px;
}

.fieldSet {
  padding: 25px 0;
  border-top: 1px solid rgba($dark, .12);

  &:first-child {
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & ul:not([class]) {
    display: flex;
    flex-wrap: wrap;

    @media #{$desktop} {
      margin: 0 -40px;
    }

    &>li {
      list-style: none;
      width: 100%;

      @media #{$desktop} {
        width: 50%;
        padding: 0 40px;
      }

      @media #{$desktop-1k} {
        width: 50%;
      }

      &.fullWidth {
        width: 100%;
      }
    }
  }
}

.fieldError {
  font-size: 11px;
  display: block;
  color: $red;
  padding: 0 15px;
}

select {
  padding-right: 25px !important;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0FEQURBRCIgZD0iTTE1LjEsNC4zYy0wLjYtMC41LTEuNC0wLjUtMiwwTDgsOC45TDMsNC4zYy0wLjYtMC41LTEuNC0wLjUtMiwwUzAuMyw1LjYsMSw2LjJsNiw1LjUNCgljMC4zLDAuMywwLjYsMC40LDEsMC40YzAuNCwwLDAuNy0wLjEsMS0wLjRsNi4xLTUuNkMxNS42LDUuNiwxNS42LDQuOCwxNS4xLDQuM3oiLz4NCjwvc3ZnPg0K');
  background-position: calc(100% - 10px) center;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-color: $light;
}


/** CUSTOM CHECKBOX **/
.styledCheck {
  font-size: 14px;

  & input {
    position: absolute;
    opacity: 0;

    &+span {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-weight: 600;
      color: $dark-500;
      transition: all 350ms ease-in-out;
    }

    &+span:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      margin-top: -3px;
      width: 15px;
      height: 15px;
      border: 1px solid $dark-900;
      background: rgba($light, .35);
      border-radius: 4px;
      transition: all 350ms ease-in-out;
    }

    &+span:after {
      content: '';
      transition: all 350ms ease-in-out;
    }

    &:hover+span:before {
      background: $primary;
    }

    &:checked+span {
      color: $primary;
    }

    &:checked+span:before {
      background: $primary;
      border-color: $primary;
    }



    &:checked+span:after {
      position: absolute;
      left: 0px;
      top: 1px;
      background: url(../../img/icons/active-check.svg) ;
      width: 14px;
      height: 14px;
      // box-shadow:
      //   2px 0 0 $light,
      //   4px 0 0 $light,
      //   4px -2px 0 $light,
      //   4px -4px 0 $light,
      //   4px -6px 0 $light,
      //   4px -8px 0 $light;
      // transform: rotate(45deg);
      
      transition: all 350ms ease-in-out;

    }


    &:disabled+span {
      color: $dark-400;
      cursor: auto;
    }

    &:disabled+span:before {
      box-shadow: none;
      background: rgba($primary, .2);
      border-color: rgba($primary, 0);
    }

    &:disabled+span:after {
      background: $primary;
      box-shadow:
        2px 0 0 $primary,
        4px 0 0 $primary,
        4px -2px 0 $primary,
        4px -4px 0 $primary,
        4px -6px 0 $primary,
        4px -8px 0 $primary;
    }
  }
}

/** CUSTOM RADIO **/
.styledRadio {
  position: relative;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle; 
  & input {
    position: absolute;
    opacity: 0;

    &+span {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-weight: 500;
      color: $dark-400;
      display: block;
      padding-left: 25px;
      transition: all 350ms ease-in-out;

    }

    &+span:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      width: 16px;
      height: 16px;
      border: 1px solid rgba($primary, .15);
      background: rgba($primary, .3);
      border-radius: 50%;
      position: absolute;
      left: 2px;
      top: 3px;
      transition: all 350ms ease-in-out;

    }

    &:hover+span:before {
      background: rgba($primary, .6);
      border-color: rgba($primary, .3);
    }

    &+span:after {
      content: '';
      border-radius: 50%;
      transition: all 350ms ease-in-out;
    }

    &:checked+span {
      color: $primary;
    }

    &:checked+span:before {
      background: rgba($primary, .3);
      border-color: rgba($primary, .15);
    }

    &:disabled+span {
      color: $primary;
      cursor: auto;
    }

    &:disabled+span:before {
      box-shadow: none;
      background: $light;
      border-color: lighten($primary, 32%);
    }

    &:checked+span:after {
      position: absolute;
      left: 5.5px;
      top: 6.5px;
      background: $primary;
      width: 9px;
      height: 9px;
      box-sizing: border-box;

    }
  }
}

/** CUSTOM SWITCH **/
.form-switch {

  // padding-left: 32px;
  & .form-check-input {
    border-color: rgba($primary, .2);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgb3BhY2l0eT0iMC40IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE1MCAxNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNCRTFFMkQ7fQ0KPC9zdHlsZT4NCjxjaXJjbGUgY2xhc3M9InN0MCIgY3g9Ijc1IiBjeT0iNzUiIHI9IjYwIi8+DQo8L3N2Zz4NCg==);
    height: 1.15rem;
    width: 2.15rem;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: rgba($primary, .2);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNTAgMTUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNTAgMTUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMTY3MDkyO30NCjwvc3R5bGU+DQo8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSI3NSIgY3k9Ijc1IiByPSI2MCIvPg0KPC9zdmc+DQo=);
    }

    &:checked {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNTAgMTUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNTAgMTUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMTY3MDkyO30NCjwvc3R5bGU+DQo8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSI3NSIgY3k9Ijc1IiByPSI2MCIvPg0KPC9zdmc+DQo=);
      background-color: rgba($primary, .2);
      border-color: rgba($primary, 0);
    }
  }
}

.phoneForm {
  display: flex;
  width: 100%;

  &-code {
    max-width: 55px;
    width: 100%;
    padding: 0 5px;
    padding-left: 0;
  }

  &-ext {
    max-width: 100px;
    width: 100%;
    padding: 0 5px;
  }

  &-num {
    width: 100%;
    padding: 0 5px;
    padding-right: 0;
  }
}

.dateForm {
  display: flex;
  width: 100%;

  &-month {
    min-width: 130px;
    max-width: 150px;
    width: 100%;
    padding: 0 5px;
    padding-left: 0;
  }

  &-day {
    min-width: 60px;
    max-width: 100px;
    width: 100%;
    padding: 0 5px;
    text-align: center;

    & input {
      text-align: inherit;
    }
  }

  &-year {
    min-width: 74px;
    max-width: 100px;
    width: 100%;
    padding: 0 5px;
    padding-right: 0;
    text-align: center;

    & input {
      text-align: inherit;
    }
  }

}

.paymentForm {
  display: flex;
  width: 100%;

  &-cry {
    max-width: 90px;
    width: 100%;
    padding: 0 5px;

    padding-left: 0;
    padding-left: 0;
  }

  &-val {
    max-width: calc(100% - 100px);
    width: 100%;
    padding: 0 5px;

    padding-right: 0;

  }

}

.qtyForm {
  display: flex;
  width: 100%;

  &-level {
    max-width: 180px;
    width: 100%;
    padding: 0 5px;

    padding-left: 0;
    padding-left: 0;
  }

  &-val {
    max-width: calc(100% - 180px);
    width: 100%;
    padding: 0 5px;

    padding-right: 0;

  }

}

/****/
.fieldName {
  display: flex;

  & .fieldWrap {
    &:first-child {
      width: 120px;
      margin-right: 15px;
    }

    &:last-child {
      width: calc(100% - 100px);
    }
  }
}

.tagWrap {
  padding: 20px 15px;
  background-color: rgba($dark-400, .05);
  border-radius: 10px;


  & .tagBox {
    border-radius: 8px;
    background-color: rgba($dark-400, .12);
    padding: 6px 12px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 7px;

    & a {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $light;
      color: $primary;
      position: absolute;
      right: -6px;
      top: -6px;
      padding: 2px;
      text-decoration: none;
      text-align: center;
      font-size: 8px;
      cursor: pointer;
    }
  }
}

.fieldRow {
  display: flex;
  margin: 0 -40px;

  & .fieldCol {
    width: 100%;
    padding: 0 40px;
  }
}

.smallNote {
  font-size: 10px;
  font-weight: bold;
  color: $dark-400;
}

.sampleNote {
  display: block;
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: $dark-400;
}

/****/
.pullUp {
  background-color: $light;
  box-shadow: 0 0 20px rgba($dark, .15);
  color: $primary;
  padding: 5px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  position: fixed;
  right: 20px;
  z-index: 3;
  bottom: 30px;
  cursor: pointer;
  border: 1px solid #009B94;
  &:hover {
    background-color: $primary;
    color: $light;
  }

  & b {
    transform: rotate(-90deg);
  }
}

.quickFAQ {
  background-color: $light;
  box-shadow: 0 0 20px rgba($dark, .15);
  padding: 5px 14px;
  color: $primary;
  font-size: 16px;
  border-radius: 0 10px 10px 10px;
  position: fixed;
  z-index: 3;
  right: 20px;
  bottom: 40px;
  cursor: pointer;

  & p {
    font-weight: 700;
    line-height: 18px;
  }

  & span {
    font-weight: 400;
    display: block;
  }


  &:hover {
    background-color: $primary;
    color: $light;
  }
}

.verfiyIcon {
  color: $green-grass;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -2px;
}

.fieldGrid {
  display: flex;
  align-items: center;
  position: relative;

  & .fieldInnerWrap {
    width: calc(100% - 70px);
  }

  &-post {
    width: 70px;
    padding-left: 10px;
    text-align: right;
  }

  &-opt {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-post+&-opt {
    right: 80px;
  }

  &-note {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }
}

.error-field {
  font-size: 11px;
  color: $red;
  //font-style: italic;
  padding: 0 12px;
  display: block;
}

.fieldAmt {
  display: flex;
  align-items: center;
  position: relative;

  &-pre {
    padding-right: 10px
  }

  &-inner {
    width: calc(100% - 50px);
    max-width: 170px;
  }

  &-post {
    padding-left: 10px
  }

}

.brWrap {
  & .fieldIcon_pre {
    color: $blue-dark;

  }

  & .fieldForm {
    background-color: rgba($blue-dark, .04);
    border-color: rgba($blue-dark, 0);
  }
}

.fieldForm {
  &.brWrap {
    background-color: rgba($blue-dark, .04);
    border-color: rgba($blue-dark, 0);
  }
}

.react-datepicker-wrapper {
  & input {
    min-height: 36px ;
    border-radius: 10px ;
    background-color: #fff ;
    border: 1px solid rgba(153, 153, 153, 0.5);
    width: 100%;
    padding: 0 14px;
    font-weight: 600;
    font-size: 16px;
    color: #2D338D;
    background-image: url(../../img/icons/cal.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center; 
  }
  
   
}

.fieldWrap  {
  & .react-datepicker {
    border: 0;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    border-radius: 15px;
  }
  & .react-datepicker-popper .react-datepicker__triangle::before,
  & .react-datepicker-popper .react-datepicker__triangle::after{
    display: none;
  }
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container,
  & .react-datepicker-popper,
  & .react-datepicker,
  & .react-datepicker__month-container,
  & .react-datepicker__header {
    // width: 100%;
  }
  & .react-datepicker__navigation {
    top: 9px;
  }

  & .react-datepicker__year-read-view--down-arrow,
  & .react-datepicker__month-read-view--down-arrow,
  & .react-datepicker__month-year-read-view--down-arrow,
  & .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
  }
  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    margin: 0.28rem;
  }
  & .react-datepicker__year-dropdown,
  & .react-datepicker__month-dropdown,
  & .react-datepicker__month-year-dropdown {
    background-color: #ffffff;
  }
  & .react-datepicker__year-option,
  & .react-datepicker__month-option,
  & .react-datepicker__month-year-option {
    font-size: 14px;
    padding: 4px 0;
  }
  & .react-datepicker__header {
    background-color: #fafafa;
    border-radius: 15px 15px 0 0px;
  }
  & .react-datepicker__day-name {
    color: #2D338D;
    font-weight: 600;
  }
}

.repeatSection {
  padding: 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12); 
& .labelSubHeading {
  margin-bottom: 30px;
}
}