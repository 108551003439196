.dropdown {
    &-toggle {
        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNC41NzU3NCA1LjQyNDI2QzQuODEwMDUgNS42NTg1OCA1LjE4OTk1IDUuNjU4NTggNS40MjQyNiA1LjQyNDI2TDkuMjQyNjQgMS42MDU4OUM5LjQ3Njk2IDEuMzcxNTcgOS40NzY5NiAwLjk5MTY3NCA5LjI0MjY0IDAuNzU3MzU5QzkuMDA4MzMgMC41MjMwNDUgOC42Mjg0MyAwLjUyMzA0NSA4LjM5NDExIDAuNzU3MzU5TDUgNC4xNTE0N0wxLjYwNTg5IDAuNzU3MzU5QzEuMzcxNTcgMC41MjMwNDUgMC45OTE2NzQgMC41MjMwNDUgMC43NTczNTkgMC43NTczNTlDMC41MjMwNDUgMC45OTE2NzQgMC41MjMwNDUgMS4zNzE1NyAwLjc1NzM1OSAxLjYwNTg5TDQuNTc1NzQgNS40MjQyNlpNNC40IDRWNUg1LjZWNEg0LjRaIiBmaWxsPSIjMDA5Qjk0Ii8+DQo8L3N2Zz4NCg==');
            background-position: center center;
            background-size: 10px 10px;
            background-repeat: no-repeat;
            border: none;
            width: 10px;
            height: 8px;
            vertical-align: middle;
            margin-top: -1px;
        }
        &.show {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &-menu {
        background: $light;
        box-shadow: 0px 0px 8px rgba($dark, 0.14);
        border-radius: 10px;
        min-width: 125px;
        border: none;

        &-end {
            //text-align: right;
        }
    }

    &-item {
        padding: 6px 12px;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            color: $blue-dark;
        }

        &.active {
            color: $blue-dark;
        }
    }
}