/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

.detailBox {
    position: relative;
    padding-bottom: calc(56px + 5px) !important;
    border: 1px solid #9699C6;
    & p {
        font-size: 16px;
        color: $dark;
        line-height: 20px; 
        padding-bottom: 15px;

        &:last-child {
            padding-bottom: 0;
             
        }
        
        & .articlesStatus {
            & span {
                position: relative;
                padding-right: 40px;
                &:first-child{
                    &::before {
                        display: none;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: -22px;
                    top: 2px;
                    width: 1px;
                    height: 14px;
                    background: #a7a7a7;
                }
                & b {
                    padding-right: 5px;
                }
            }
        }
       
        & .textBadge {
            position: absolute;
            right: 15px;
            top: 19px;
        }
    }

    & h2 {
        color: $primary;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
    }

    & h3 {
        font-size: 11px;
        font-weight: 300;

        & span {
            line-height: 20px;
            font-size: 16px;
            font-weight: 700;
            color: $primary;
            padding: 0 10px;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                right: -21px;               
                margin-right: 20px;
                background: #000000;
                height: 12px;
                width: 1px;
                top: 4px;
            }
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                &::before{
                    display: none;
                }
            }
        }
    }



    &-foot {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 16px 24px;
        background-color: rgba($blue-dark, .06);
        border-radius: 0 0 20px 20px;

        & p {
            color: $dark;
            font-weight: 300;
            font-size: 11px;
            display: flex;
            align-items: center;

            & span {
                color: $dark-500;
                font-size: 16px;
                padding: 0 20px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                & b {
                    font-weight: 500;
                    color: $dark-400;
                }

            }
        }
    }
}

.statusWrap {
    padding-top: 50px;

    & h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        //color: $blue-dark;
        padding-left: 30px;
        padding-bottom: 22px;
        margin-bottom: 0;
    }
}

.statusBox {
    border-radius: 15px;
    background-color: rgba($blue-dark, .06);
    padding: 18px;
    margin-bottom: 30px;
    border: 1px solid rgba($blue-dark,.35);


    &-head {
        border-bottom: 1px solid #a7a7a7;
        padding: 18px 0;
        padding-top: 0;
        font-size: 16px;
        color: $dark;
        margin: 0 auto;

        & h3 {
            margin: 0;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    &-body {
        padding-top: 18px;

        & ul {
            display: flex;
            align-items: flex-start;

            & li {
                list-style: none;
                padding-right: 65px;

                &:last-child {
                    padding-right: 0;
                }

                & b {
                    font-weight: 600;
                    display: block; 
                    text-transform: uppercase;
                    font-size: 16px;
                    margin-bottom: 6px;
                }
                & strong {
                    font-weight: 500;
                    
                    font-size: 16px;
                }

                & small {
                    display: block;
                    color: #000000;
                    font-size: 16px;
                    margin-bottom: 10px;
                    line-height: 16px;
                    font-weight: 500;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.passText{
                        color: #F16136;
                    }
                }

                & a {
                    font-size: 16px;
                    display: table;
                }

                & h4 {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    color: $primary;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                }
            }
        }

        & .revisionWrap {
            border-bottom: 1px dashed rgba($dark, .3);
            margin-bottom: 20px;
            padding: 0 12px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;

                & ul {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            & ul {
                width: 100%;
                padding: 10px 0;

                & li {
                    width: 100%;
                    padding-bottom: 10px;

                    &:last-child {
                        padding-right: 0;
                    }

                }

                &.fullWidth {
                    justify-content: space-between;

                    & li {
                        min-width: 15%;
                        width: auto;
                        padding-right: 55px;

                        &:last-child {
                            padding-right: 0;
                        }

                    }
                }
            }
        }
    }
}
.tabContentWrap {
    min-height: 430px;
    position: relative;
    
  }
  .notArticle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & figure {
        background: #EBF4F4;
        width: 60px;
        height: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    & h2 {
        display: block;
        width: 100%;
        color: #F16136;
        font-weight: 700;
        font-size: 30px;
    }
    & p{

    }
  }

  .publishedRate {
    background: #F9F9FD;
    padding: 15px 28px;
    margin-left: -25px;
    margin-bottom: -30px;
    width: calc(100% + 50px);
    border-radius: 0 0px 20px 20px;
    & span {
        font-size: 16px; 
        font-weight: 600; 
        color: #404040;
        position: relative;
        padding-left: 26px;
        vertical-align: middle;
        &.rate04 {
            & b {
            font-size: 16px;
            font-weight: 600;
            color: #404040;
        }
        }
        &.rate01 {
            & b {
            font-size: 16px;
            font-weight: 600;
            color: #404040;
            }
        }
        &.rate03 {
            & b {
            font-size: 16px;
            font-weight: 700;
            color: #BF1E2E;
            }
        }
        &:first-child{
            padding-left: 0;
            &::before{
                display: none;
            }
        }
        &::before{
            content: '';
            position: absolute;
            left: 12px;
            top: 4px;
            background: #a7a7a7;
            width: 1px;
            height: 14px;
        }
        & i {
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: #404040;
            margin: 0 8px;
            display: inline-block;
            vertical-align: middle;
        }
        & b {
            color: #009B94;
            font-weight: 700;
            vertical-align: middle;
            position: relative;
            padding-left: 16px;
            &::before{
                content: '';
                position: absolute;
                left: 6px;
                top: 8px;
                width: 4px; 
                height: 4px;
                background: #404040;
                border-radius: 2px;
            }
        }
    }
  }
  .mudracountTd {
    padding-right: 17px;
  }
  .transactionHistory {
    width: 150px;
    & .text-link {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
        width: 100%;
    }
    & span {
        display: block;
        width: 160px;
        vertical-align: middle;
        & b {
            width: 80px;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: middle;
        }
    }
  }
  .paddingManage {
    padding-right: 32px !important;
  }
  .mr-0 {
    margin-right: 0;
  }

  .downloadLink {
    display: block;
    cursor: pointer;
    & span {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #009B94;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        & b {
            color: #ffffff;
        }
    }
    & i {
        display: block;
        font-style: normal;
        text-align: center;
        color: #404040;
        font-weight: 500;
    }
  }
  .pb-2 {
    padding-bottom: 16px !important;
  }