/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/***/
header {
    background-color: $light;
    box-shadow: 0 0 20px rgba($dark-200, .1);
    width: 100%;
    margin: 0 auto;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 99;
    transition: all 350ms ease-in-out;
    border-bottom: 1px solid rgba($highlight, .5);
    min-height: 90px;

    & .menuToggle {
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark-400;
        font-size: 150%;
        margin-left: -15px;
        position: relative;
        z-index: 1;
        padding: 4px;

        @media #{$desktop} {
            display: none;
        }
    }

    & .cmyLogo {
        max-width: 100px;
        display: block;
        width: 100%;

        @media #{$desktop} {
            max-width: 200px;
        }

        & img {
            max-width: 100%;
            display: block;
        }
    }

    & .navWrap {
        position: fixed;
        left: 0;
        top: 50px;
        width: 100%;
        height: calc(100vh - 50px);
        background-color: $light;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 350ms ease-in-out;

        &.active {
            transform: translateX(0);
            opacity: 1;
        }

        @media #{$desktop} {
            display: flex;
            vertical-align: middle;
            align-items: center;
            justify-content: end;
            width: calc(100% - 200px);
            position: relative;
            height: auto;
            top: 0;
            background-color: transparent;
            transform: translateX(0);
            opacity: 1;

            &.active {
                transform: translateX(0);
                opacity: 1;
            }


        }

        & .navList {
            & ul {
                margin: 0;
                padding: 0;

                @media #{$desktop} {
                    display: flex;
                    align-items: center;
                }

                & li {
                    list-style: none;
                    padding: 30px 30px;
                    border-bottom: 1px solid rgba($dark, .75);

                    @media #{$desktop} {
                        padding: 0 24px;
                        border-bottom: none;

                        &:first-child {
                            padding-left: 0;
                        }

                    }

                    & a {
                        text-decoration: none;
                        cursor: pointer;
                        color: $secondary;
                        font-weight: 500;
                        font-size: 16px;
                        text-decoration: none !important;

                        &.active {
                            font-weight: 800;
                        }

                        &:hover {
                            opacity: .85;
                        }
                    }
                }
            }


        }
    }

    & .navSign {
        display: flex;
        align-items: center;
        border-left: 1px solid rgba($dark, .08);
        padding: 4px 0;
        padding-right: 128px;

        @media #{$desktop} {
            margin-left: 24px;
            padding-right: 154px;
        }

        & a {
            font-weight: 500;
            font-size: 14px;
            text-decoration: none;
            cursor: pointer;
            display: inline-block;

            @media #{$desktop} {
                font-size: 16px;

            }

            &:hover {
                opacity: .85;
            }
        }

        & .signIn {
            color: $secondary;
            margin: 0 20px;

            @media #{$desktop} {
                margin: 0 24px;
            }

            &.active {
                font-weight: 800;
            }
        }

        & .signUp {
            height: 100%;
            display: flex;
            background-color: $secondary;
            color: $light;
            padding: 10px 15px;
            text-align: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;


            @media #{$desktop} {
                border-radius: 20px;
                padding: 20px;
                width: 174px;

            }

        }
    }


    & .navheader {
        display: flex;
        align-items: center;
        padding: 16px 0;

        & a:not(.button) {
            font-size: 16px;
            font-weight: 700;
            color: $primary !important;
            text-decoration: none;
        }

        & .navLink {
            padding: 0 0;
            margin-right: 10px;
            position: relative;
            &:after {
                content: "";
                width: 1px;
                height: 14px;
                background-color: rgba($dark-ad, 1);
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: none;
            }

            & ul {
                display: flex;
                align-items: center;

                & li {
                    list-style: none;
                    padding: 0 20px;
                    position: relative;

                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }

                    &:before {
                        content: "";
                        width: 1px;
                        height: 14px;
                        background-color: rgba($dark-ad, 1);
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                    }
                    &.active{
                        & a {
                            position: relative;
                            color: #404040 !important;
                            &:after{
                                content: "";
                                width: 34px;
                                height: 8px;
                                border-radius: 8px;
                                background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                                display: block;
                                position: absolute;
                                left: 0;
                                bottom: -8px;
                                z-index: 1;
                            }
                        }
                    }

                }
            }
        }

        & .navNotify {
            padding: 0 15px;

            & a {
                font-size: 150%;
                display: flex;
                align-items: center;
                position: relative;

                & span {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $primary;
                    position: absolute;
                    right: 3px;
                    top: 2px;
                }
            }
        }

        & .navOnline {
            padding: 0 15px;
            font-weight: 600;
            color: $primary !important;
            font-size: 12px;
            display: flex;
            align-items: center;

            & .form-switch {
                margin-right: 5px;
            }
        }

        & .navDashboard {
            padding: 0 15px;

            & .dropdown-toggle {
                border-color: $primary;
                color: $primary;
                font-weight: 600;
                border-radius: 10px;
                min-width: 150px;
            }

        }

    }

    @media #{$desktop} {
        &.fixed {
            position: fixed;
            left: 50%;
            top: 0;
            border-radius: 0;
            transform: translate(-50%, 0);
            width: 100%;
            max-width: 100%;
            margin: 0 auto;

            & .signUp {
                border-radius: 0;
            }
        }
    }
}



 