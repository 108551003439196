/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/***/
.accordionWrap {
  border-top: 1px solid $dark-700 ;
  border-bottom: 1px solid $dark-700 ;
  padding: 30px 0;

  &-head {
    position: relative;

    & h3 {
      font-size: 18px;
      color: $orange;
      font-weight: 700;
      margin: 0;
    }

    & a {
      color: $primary !important;
      font-weight: 700;
      font-size: 16px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
      & b {
        margin-left: 5px;
        font-size: 70%;
      }
      &.show {
        & b {
          transform: rotate(180deg);
          display: inline-block;
        }
      }
    }
  }

  &-body {
    padding-top: 10px;
  }
}