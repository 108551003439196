$icomoon-font-family: "vs" !default;
$icomoon-font-path: "../font_icons/fonts" !default;

$icon-no_notes: "\e918";
$icon-review_notes: "\e931";
$icon-rejection_notes: "\e932";
$icon-edit: "\e933";
$icon-edit_message: "\e934";
$icon-done: "\e936";
$icon-flag: "\e937";
$icon-heart: "\e938";
$icon-dot: "\e939";
$icon-play: "\e93a";
$icon-share: "\e93b";
$icon-saved: "\e93c";
$icon-caution: "\e921";
$icon-copy: "\e924";
$icon-wallet: "\e925";
$icon-persons: "\e926";
$icon-dashboard: "\e929";
$icon-dustbin: "\e919";
$icon-fullscreen: "\e91a";
$icon-plus: "\e91e";
$icon-minus: "\e91f";
$icon-facebook: "\e900";
$icon-facebook_fill: "\e92e";
$icon-tweet: "\e922";
$icon-tweet_fill: "\e92c";
$icon-linkdin: "\e902";
$icon-linkdin_fill: "\e92d";
$icon-instagram: "\e93d";
$icon-instagram_fill: "\e901";
$icon-home: "\e904";
$icon-mail: "\e905";
$icon-register: "\e906";
$icon-rupee: "\e90c";
$icon-send: "\e90d";
$icon-tool: "\e911";
$icon-eye: "\e920";
$icon-lock: "\e930";
$icon-camera: "\e912";
$icon-building: "\e914";
$icon-link: "\e916";
$icon-lens: "\e92b";
$icon-help: "\e903";
$icon-email: "\e913";
$icon-call: "\e915";
$icon-bell: "\e928";
$icon-pencil: "\e92f";
$icon-check: "\e927";
$icon-logout: "\e92a";
$icon-upload: "\e917";
$icon-download: "\e93e";
$icon-date: "\e93f";
$icon-profile: "\e935";
$icon-bin: "\e90b";
$icon-arrow-long-down: "\e91b";
$icon-long-arrow: "\e940";
$icon-arrow-long-up: "\e91c";
$icon-arrow-long-left: "\e923";
$icon-tick: "\e91d";
$icon-arrow-left: "\e909";
$icon-arrow-down: "\e907";
$icon-arrow-up: "\e908";
$icon-arrow-right: "\e90a";
$icon-sort: "\e941";
$icon-cross: "\e90e";
$icon-dots-hr: "\e90f";
$icon-dots-vr: "\e910";

