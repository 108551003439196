.reactSelect {
    &__control {
        min-height: 36px !important;
        border-radius: 10px !important; 
        background-color: #F9F9FD !important;
        border-color: transparent !important;
        width: 100%;
        box-shadow: none !important;
        font-family: $familyDefault;
        &:hover,
        &--is-focused {
            border-color: rgba($dark-900, .5) !important;
        }
    }

    &__placeholder,
    &__input-container {
        font-size: 14px !important;
    }

    &__value-container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    &__single-value {
        font-weight: 600;
        color: $blue-dark !important;
    }

    &__multi-value {
        font-size: 14px;
        background-color: rgba($blue-dark, .04) !important;

        &__label {
            font-weight: 600;
            color: $blue-dark !important;
        }
    }

    &__indicator {
        padding: 10px !important;
        color: $dark-bb !important;

        & svg {
            width: 16px;
            height: 16px;
        }

        &-separator {
            display: none;
        }
    }

    &__clear-indicator {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    &__dropdown-indicator {}

    &__menu {
        font-size: 14px;
    }

    &__option--is-focused,
    &__option--is-selected {
        color: $blue-dark !important;
        background-color: rgba($blue-dark, .04) !important;
        font-weight: 700 !important;
    }

    &__menu-list--is-multi {
        & .reactSelect__option {
            // padding-left: 20px;

            & input[type="checkbox"] {
                appearance: auto;
                margin-right: 4px;

                &:checked {
                    background: $blue-dark !important;
                    color: $light !important;
                }
            }

        }
    }

}


 
 