.vsTable {
    &-main {
        width: 100%;
        font-size: 16px;
        color: $dark;
        border-collapse: separate;
        border-spacing: 0 12px;

        & th,
        & td {
            padding: 14px 15px;
        }

        & thead {

            & th {
                text-transform: uppercase;
                font-size: 13px;
                color:$highlight;
                font-weight: 600;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba($dark-ad, .3);
                white-space: nowrap;
                & b {
                    font-size: 85%;
                    font-weight: 400;
                    margin-left: 2px;
                }

                &:last-child {
                    text-align: center; 
                }
            }
        }

        & tbody {
            & td {
                padding-top: 12px;
                padding-bottom: 12px;
                font-size: 15px;
                color: $dark;
                border-top: 1px solid rgba($blue-dark, .25);
                border-bottom: 1px solid rgba($blue-dark, .25);

                & a {
                    text-decoration: none;
                    font-weight: 600;
                    color: $primary;
                    text-transform: capitalize;
                }

                & .badgeBox {
                    margin-top: 4px;
                }
                & .alignLeft.dropdown  {
                    & .dropdown-toggle {
                        padding-left: 0;
                    }
                }
                & .dropdown-toggle {
                    text-decoration: none;
                    font-weight: 600;
                    color: $primary;
                    border: none;

                    &:focus {
                        border: none; 
                    }
                }

                &:first-child {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    border-left: 1px solid rgba($blue-dark, .25);
                }

                &:last-child {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    border-right: 1px solid rgba($blue-dark, .25);
                }
            }

            & tr {
                background-color: rgba($blue-dark, .03);

            }
        }

    }
}

.emptyMsg {
    text-align: center;
    padding: 140px 0;

    & span {
        width: 94px;
        height: 94px;
        background-color: rgba($highlight, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 50%;
    }

    & h2 {
        font-weight: 700;
        font-size: 28px;
        margin: 10px 0;
        color:$highlight;
    }

    & p {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: $dark;
    }
}

.paginationWrap {
    & .pagination {
        text-align: center;
        padding-top: 15px;
        justify-content: center;
        align-items: center;

        & .item {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $primary;
            text-decoration: none !important;
            cursor: pointer;
            padding: 0 9px;
            display: inline-flex;
            align-items: center;

            &:hover {
                opacity: .85;
            }

            &.active {
                color: $dark-400;
            }

            &:first-child,
            &:last-child {
                font-size: 8px;
                font-weight: 400;
            }
        }
    }
}

.review {

    &Title {
        font-size: 14px;
        color: $dark-400;
        font-weight: 500;
        display: block;
    }

    &Timestamp {
        font-size: 12px;
        color: $dark-400;
        display: block;
    }

    &Notyet {
        font-size: 14px;
        color: $dark-400;
        display: block;
        max-width: 125px;
    }
}


