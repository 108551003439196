/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/****/
.taskCard {
    padding: 22px;
    background-color: rgba($dark-400, .06);
    border-radius: 16px;
    margin-top: 12px;

    & .labelBadge {
        margin: 0;
        font-size: 12px;
        color: $dark;
        padding-right: 12px;
        padding-left: 12px;
        margin-bottom: 12px;
        display: inline-table;
    }

    &-cover {
        background-color: $light;
        padding: 18px 0;
        margin: -22px -22px 0 -22px;
        border-radius: 16px 16px 0 0;

        & .taskCard-intro {
            padding-right: 22px;
            padding-left: 22px;
        }

        & .taskCard-post {
            padding-right: 22px;
            padding-left: 22px;
        }

    }

    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 22px;
        padding-bottom: 0;
        margin: 0 -22px;
    }

    &-intro {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: $dark-700;
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba($dark-400, .12);
    }

    &-img {
        width: 38px;
        height: 38px;
        display: block;
        border-radius: 50%;
        overflow: hidden;
    }

    &-info {
        padding-left: 10px;
    }

    &-left {
        display: flex;
        align-items: center;

        & p {
            font-weight: 700;
            color: $primary;
            font-size: 16px;
            line-height: 18px;
        }

        & small {
            font-weight: 400;
            color: $dark-800;
            font-size: 12px;
            display: inline-block;
        }

        & a {
            color: $primary !important;
            font-weight: 700;
            font-size: 14px;
            margin-left: 10px;
            display: inline-block;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    &-note {
        padding: 0 10px;
        margin-left: 20px;

        & label {
            color: $dark-400;
            font-size: 12px;
            font-weight: 400;
            display: block;
            line-height: 10px;
        }

        & p {
            font-weight: 700;
            color: $dark;
            font-size: 16px;
        }
    }

    &-text {
        padding-right: 140px;
        position: relative;
        color: $dark;

        & p {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }

        & a {
            font-weight: 700;
            color: $primary !important;
            text-decoration: none;
            cursor: pointer;

            &.chartLink {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &-post {
        position: relative;
        color: $dark;

        & p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            padding-bottom: 10px;

            &:last-child {
                padding-bottom: 0;
            }
        }

        & a {
            font-weight: 700;
            color: $primary !important;
            text-decoration: none;
            cursor: pointer;
        }

    }

}


.postList {
    & .taskCard {
        box-shadow: 0 0 8px rgba($dark, .08);
    }

}


.requestDetail {

    & .col {

        & h3 {
            margin-bottom: 5px;
        }

        & .labelBadge {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            color: $dark;
            padding-right: 12px;
            padding-left: 12px;
            margin-bottom: 12px;
            display: inline-table;
            font-style: normal;
        }
    }

    & .wrapBadge {
        background-color: rgba($dark-400, .1);
        padding: 10px 15px;
        border-radius: 10px;
        display: inline-block;

        & h3 {
            margin-bottom: 0;
        }

        & p {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
        }
    }

    & h3 {
        font-size: 14px;
        color: $dark-400;
        margin: 0;
        font-weight: 400;
        margin-bottom: 10px;
    }

    & h4 {
        font-size: 18px;
        font-weight: 600;
        color: $dark;
    }

    & p {
        font-size: 14px;
        color: $dark;
    }



    & .minMax {
        justify-content: flex-start;
        margin: 0;

        & label {
            color: $dark;

        }

        & p {
            font-weight: 600;
            font-size: 14px;
            color: $dark;
        }
    }
}


/** ARTICLE BOX **/
.postList {
    & .articleCard {
        box-shadow: 0 0 8px rgba($dark, .08);
    }
}

.articleCard {
    padding: 20px;
    background-color: rgba($dark-400, .03);
    border-radius: 16px;
    margin-top: 12px;

    & .labelBadge {
        margin: 0;
        font-size: 10px;
        color: $secondary;
        padding-right: 8px;
        padding-left: 8px;
        margin-bottom: 8px;
        display: inline-table;
        font-weight: 500;
        background-color: rgba($secondary, .08);
    }

    &-cover {
        background-color: $light;
        padding: 18px 0;
        margin: -20px;
        border-radius: 16px;

        & .articleCard-intro {
            padding-right: 20px;
            padding-left: 20px;
        }

        & .articleCard-post {
            padding-right: 20px;
            padding-left: 20px;
        }

    }

    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 22px;
        padding-bottom: 0;
        margin: 0 -22px;
    }

    &-intro {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: $dark-700;
        display: flex;
        align-items: center;
        padding-bottom: 5px;
    }

    &-info {
        width: 100%;
    }


    &-left {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding-right: 20px;

        & h4 {
            font-weight: 600;
            color: $primary;
            font-size: 18px;
            line-height: 22px;
            margin: 8px 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        & p {
            font-weight: 400;
            color: $dark-400;
            font-size: 12px;
            line-height: 22px;
            font-style: italic;
            margin-top: -8px;
            margin-bottom: 8px;
        }

        & small {
            font-weight: 400;
            color: $dark-900;
            font-size: 12px;
            display: inline-block;

            & a {
                font-size: 12px;
                color: $secondary !important;

            }
        }

        & a {
            color: $primary !important;
            font-weight: 700;
            font-size: 16px;
            margin-left: 10px;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
        }
    }

    &-right {
        min-width: 100px;

        & p {
            font-weight: 600;
            color: $secondary;
            font-size: 16px;
            max-width: 90px;
            margin-top: 2px;
            margin-left: 10px;
            text-align: left;
        }
    }


    &-text {
        position: relative;
        color: $dark-400;

        & p {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }

        & .linkOpt {

            & .linkIcon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                padding: 5px;
                margin-bottom: 2px;

                &.fill {
                    background-color: $primary;
                    color: $light;
                }
            }

            & a {
                font-size: 10px;
                color: $dark-400 !important;
                font-weight: 300;
                font-style: italic;
                text-align: center;
                text-decoration: none;
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;

                &:hover {
                    opacity: .85;
                }

                & img {
                    max-width: 100%;
                    display: block;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

        }


    }

    &-author {
        font-weight: 700;
        color: $primary !important;
        text-decoration: none;
        display: block;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 2px;
    }

    &-post {
        position: relative;
        color: $dark-400;

        & p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            padding-bottom: 4px;

            &:last-child {
                padding-bottom: 0;
            }
        }

        & a {
            font-weight: 700;
            color: $primary !important;
            text-decoration: none;
            cursor: pointer;
        }

    }


}


.analyticsBox {
    margin-top: 15px;
}


.articleStep {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-intro {
        & h2 {
            font-size: 18px;
            font-weight: 500;
            color: $secondary;
            margin-bottom: 0;
        }

        & p {
            font-size: 14px;
            color: $dark-400;
        }
    }

    &-step {
        & ul {
            display: flex;
            align-items: center;
            color: $secondary;
            font-size: 10px;
            font-weight: 700;
            position: relative;

            &:before {
                content: "";
                width: calc(100% - 80px);
                height: 1px;
                background-color: $secondary;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin-top: -10px;
            }

            & li {
                list-style: none;
                padding: 0 40px;
                text-align: center;
                position: relative;
                z-index: 1;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                & span {
                    width: 40px;
                    height: 40px;
                    border: 2px solid $secondary;
                    border-radius: 50%;
                    background-color: lighten($secondary, 66%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0 auto;
                    margin-bottom: 5px;

                }
            }
        }

    }
}
.dashboard-keywords{
.imgClose {
    position: absolute;
    width: 15px;
    height: 15px;
    display: inline-block;
    right: -8px;
    top: -8px;
    background: #404040 url(../../img/icons/white-cross.svg);
    // background: $color_gray url(../../assets/img/icons/white-cross.svg);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    background-size: 6px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.t-tag {
    border-radius: 5px;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #009B94;
    display: inline-block;
    border: 0.5px solid #009B94 !important;
    margin-right: 8px !important;
    margin-top: 9px !important;
    overflow: visible;
}
}