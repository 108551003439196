$color1: #009B94;
$color2: #FF8300;
$color3: #009B94;
$color_gray: #404040;
$color_black: #000000;
@font-face {
  font-family: 'calibriregular';
  src: url('../../font_icons/fonts/calibri_regular-webfont.woff2') format('woff2'),
       url('../../font_icons/fonts/calibri_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
.right180{
  right: 180px !important;
}
.staricon{
  padding-right: 5px;
  width: 20px;
  line-height: 0;
  margin-bottom: 3px;
}
.px_20{
  display: inline-block;
  width: 20px;
  height: 20px;
}
.ds_table_star td{
  padding-left: 5px;
}
.opacity-5 {
  opacity:0.5 !important;
  pointer-events: none;
}
.pl-0 { padding-left: 0; }
.mb-25 {
  margin-bottom: 25px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mr-4 {
  margin-right: 25px !important;
}
.text-left{
  text-align: left !important;
}
.react-datepicker__month-container {
    width: auto;
} 


.innerMergin {
  max-width: 1440px;
  margin: 0 auto;
}
.innerMergin.customds {
  font-size: 32px;
  margin:0px ;
  max-width: 70vw;
}
.color-red{
  color: #BF1E2E;
}
.pointernone{pointer-events: none;}
.imgShowBox {  
  & b {
      width: 102px;
      height: 80px;
      margin-right: 30px;
      display: inline-block;
      position: relative;
      background-position: top;
      background-size: cover;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 10px;
      overflow: hidden;
      & img {
          max-width: 100%;
          border-radius: 4px;
          max-height: 100%;
      }
      
  }
}

.t-tag-box {
  border-top: 0.5px solid #97979b;
  padding-top: 4px;
  margin-top: 10px;
}
.t-tag {
  border-radius: 5px;
  padding: 3px 8px 0px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #009b94;
  display: inline-block;
  border: 0.5px solid #009b94;
  margin-right: 6px;
  margin-top: 9px;
  max-width: 48%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &.t-tag-journal {
    max-width: none;
    white-space: normal;
  }
}
.t-tag-type {
  padding: 3px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ff8300;
  display: inline-block;

  margin-right: 10px;
  margin-top: 9px;
}
.keyBox {
  & .t-tag {
    position: relative;
    margin-top: 15px;
    word-break: break-all;
  }
}
.tagsNewWrap {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 0.5px solid #a7a7a7;
  & .t-tag {
    border-radius: 10px;
    padding: 8px;
  }
}

.graytext-link  {
  font-weight: 600;
  cursor: pointer;
}

.keyBox.previewKey {
  margin-top: 25px;
  margin-bottom: 25px;
  & .previewKeyArea {
    & .t-tag {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
  & .t-tag {
    border: 0;
    position: relative;
    margin: 0;
    &::before {
      content: "";
      position: absolute;
      right: 0px;
      width: 1px;
      height: 11px;
      top: 7px;
      background: #009b94;
    }
  }
}
.tagContent {
  border: 0.5px solid rgba(153, 153, 153, 0.5);
  border-radius: 10px;
  padding: 11px 14px;
}
.tagTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ff8300;
  margin-bottom: 12px;
  display: block;
  & .fileBox {
    float: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #009B94;
    cursor: pointer;
    & input {
      display: none;
    }
  }
}
.abbreViewWrap {
  position: relative;
}
.abbreView {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #009b94;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 10px;
  display: inline-block;
  & b {
    font-weight: 600;
    padding: 0;
    display: inline-block;
    line-height: 18px;
    &:first-child {
      padding-right: 10px;

      margin-right: 10px;
      position: relative;
      &::before {
        content: "";
        background: #009b94;
        width: 1px;
        height: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.docShowBox {
  display: block;
  & .docShowList {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
    display: inline-block;
    border: 0.5px solid #009b94;
    color: #009b94;
    border-radius: 10px;
    padding: 6px 10px;
    & img {
      max-width: 12px;
    }
  }
  &.supplimentPreview {
    & .docShowList {
      & a {
        position: relative;
        text-decoration: none;
        & b {
          width: 22px;
          height: 22px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #009B94 url(../../img/icons/downloadfile.svg);
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 12px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }
    }
  }
  &.suppleBox {
    & .docShowList {
      display: inline-block;
      margin-right: 12px;
      & a {
        position: relative;
        display: inline-block;
        padding: 4px 0;
        color: #009b94;
        // color: #009B94;
        text-decoration: none;
        padding-right: 30px;
        & span {
          color: #009b94;
        }
        & .imgClose {
          right: 8px;
          top: 6px;
        }
        & b {
          width: 22px;
          height: 22px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #009B94 url(../../img/icons/downloadfile.svg);
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 12px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }
    }
  }
}
.editorHeadMain {
  width: 100%;
  display: inline-block;
  padding: 15px 44px;
  background: linear-gradient(270deg, #fef1d4 0%, #faf9e2 100.77%);
  & .editorHead {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      color: #009b94;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}

.editorAction {
  & a {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    text-decoration: none;
    margin-left: 28px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      color: #009b94 !important;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    &::before {
      content: "";
      position: absolute;
      right: -14px;
      top: 2px;
      background: #404040;
      width: 1px;
      height: 17px;
    }
    &.active {
      color: #404040;
      opacity: 1;
      &:after {
        content: "";
        width: 34px;
        height: 8px;
        border-radius: 8px;
        background: linear-gradient(
          270deg,
          rgba(255, 176, 27, 0.84) -2.89%,
          rgba(227, 232, 123, 0.84) 100%
        );
        display: block;
        position: absolute;
        left: 0;
        bottom: -13px;
        z-index: 1;
      }
    }
  }
}

.previewHero {
  height: 265px;
  width: 100%;
  background: #f1f1f1;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  & h3 {
    position: absolute;
    left: 45px;
    bottom: 40px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.previewTags {
  border-bottom: 0.5px solid #a7a7a7;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  & ul {
    display: flex;
    & li {
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #000000;
      position: relative;
      list-style: none;
      margin-right: 55px;
      font-weight: 500;
      &::before {
        content: "";
        position: absolute;
        right: -27px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background: #a7a7a7;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.publiseTitle {
  padding: 12px 44px;
  font-weight: 700;
  font-size: 22px;
  color: #ff8300;
}

.publiseTags {
  display: block;
  background: rgba(0, 155, 148, 0.06);
  padding: 0 44px;
  & span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #009B94;
    display: inline-flex;
    padding: 10px 10px 10px 0px;
    margin-right: 35px;
    position: relative;
    &:last-child {
      &::before {
        display: none;
      }
    }
    &::before {
      content: "";
      width: 1px;
      height: 12px;
      background: #a7a7a7;
      top: 50%;
      right: -18px;
      position: absolute;
      transform: translateY(-50%);
    }
    & b {
     // background: url(../../src/svg/id.svg);
    background: url(../../img/icons/id.svg);
      width: 19px;
      height: 19px;
      margin-left: 8px;
    }
  }
}

// .publishedBox {
//   padding: 15px 44px;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
//   border-bottom: 0.5px solid #a7a7a7;
//   display: flex;
//   & i {
//     font-style: normal;
//     position: relative;
//     margin-right: 46px;
//     &::before {
//       content: "";
//       position: absolute;
//       right: -23px;
//       top: 50%;
//       transform: translateY(-50%);
//       width: 1px;
//       height: 12px;
//       background: #a7a7a7;
//     }
//   }
//   & a {
//     text-decoration: none;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 19px;
//     padding-left: 16px;
//     position: relative;
//     &::before {
//       content: "";
//       position: absolute;
//       left: 0;
//       top: 50%;
//       transform: translateY(-50%);
//       width: 14px;
//       height: 11px;
//       // background: url(../img/icons/url_icon.svg);
//       background-repeat: no-repeat;
//     }
//   }
// }

.publishedBox {
  border-bottom: 0.5px solid #A7A7A7;
  padding: 15px 44px;
  & .innerMergin {
      
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
 
      display: flex;
      font-weight: 400;
  }
  & i {
      font-style: normal;
      position: relative;
      margin-right: 46px;
      &::before{
          content: '';
          position: absolute;
          right: -23px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background: #a7a7a7;
      }
  }
  & a {
    &.fullscreenbtn{
      font-size: 15px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 11px;
        background: url('../../img/icons/orange-link-icon.svg');
        background-repeat: no-repeat !important;
    }
    }
      text-decoration: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      padding-left: 16px;
      position: relative;
      &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 14px;
          height: 11px;
          //background: url(../img/icons/url_icon.svg);
          background-repeat: no-repeat;
      }
  }
  & span {
      font-weight: 600;
  }
}

.publisActionWrap {

  padding: 10px 44px;
  border-bottom: 0.5px solid #A7A7A7;
 & .innerMergin {
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
  & .publisActionBtn {
      display: flex;
      align-items: center;
      justify-content: center; 
      & ul{
          display: flex;
          & li{
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              text-transform: uppercase;
              color: #009B94;
              list-style: none;
              cursor: pointer;
              margin-right: 50px;
              position: relative;
             &:hover{
              & .publisNav {
                  display: block;
                  
              }
             }
              &:last-child{
                  &::before{ 
                      display: none;
                  } 
              }
              &::before{
                  content: '';
                  position: absolute;
                  right: -25px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 12px;
                  background: #a7a7a7;
              }
              & img {
                  vertical-align: middle;
                  display: inline-block;
              }
              & i {
                  font-style: normal;
                  padding-left: 4px;
                  vertical-align: middle;
              }
             
          }
      }
      
  }
}

.publisNav {
  display: none;
  position: absolute; 
  left: 0;
  top: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  min-width: 156px;
  z-index: 1;
  & button {
      display: block;
      border-bottom: 0.5px solid rgba(167, 167, 167, 0.14) !important;
      width: 100%;
      & a {
          text-transform: capitalize;
      }
  } 
  & a {
      border-bottom: 0.5px solid rgba(167, 167, 167, 0.14);
      padding: 9px 10px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #009B94;
      text-transform: capitalize;
      &:not([href]) { 
          color: #009B94 !important;  
      } 
      & b {
          width: 30px;
          height: 30px;
          background: #FFFFFF;
          border: 0.5px solid #009B94;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
      }
      &:last-child {
          border-bottom: 0;
      }
  }
}
.publiceDetailsWrap {
  margin-top: 20px;
  & ul {
    display: flex;
    background: #f8fafa;
    border: 0.5px solid #404040;
    border-radius: 12px;
    padding: 12px;
    justify-content: space-between;
    & li {
      list-style: none;

      & p {
        text-align: center;
        & span {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #404040;
          display: block;
          padding-bottom: 4px;
        }
        & b {
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;
          color: #16ace4;
        }
      }
    }
  }
}
.publiceUiBox { 
  padding-left: 30px;
  counter-reset: figures;
  & figure {
    counter-increment: figures;
    padding-bottom: 34px;
    margin-bottom: 0;
    position: relative;
    text-align: center;
}
& figure:after {
    content: 'Figure. ' counter(figures);
    color: #ff8300;
    margin-bottom: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;

}
  & .commanBox {
    margin-bottom: 25px;    
    border: 0;
    box-shadow: none;
    padding: 0;
    

    &.amendementBox{
      border: 1px solid #009B94;
      background: rgba(255, 131, 0, 0.1);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      padding: 20px;
      & .attechmentHead {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        & i {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          color: #BF1E2E;
          border: 1px solid #BF1E2E;
          padding: 3px 10px;
          font-style: normal;
          border-radius: 6px;
          margin-right: 10px;
        }
        & span {
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid #d7d7d7;
        }
      }
      & label {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #009B94;
       
        margin-bottom: 0;
      }
      & textarea {
        min-height: 210px;
        border-radius: 10px;
        width: 100%;
        border: 0.5px solid #404040;
        background-color: transparent;
        padding: 12px;
      }
    }
  }
  & .previewBox {
    margin-top: 0; 
    counter-reset: tableCount;
    & table {
      counter-increment: tableCount;
      position: relative;
      margin-bottom: 25px;        
  }
  & table:after {
      content: 'Table. ' counter(tableCount);
      color: #ff8300;
      margin-bottom: 5px;
      position: absolute;
      bottom: -30px;
      left: 0;
      font-weight: 600;
      width: 100%;
      text-align: center;
  }
  }
}
.previewMain {
  padding: 0 44px;
  & .labelForm {
    font-weight: 600;
  }
   
}
.previewBox {
  margin-top: 48px;
}
.contentPreview {
  & a {
    position: relative;
    & .refranceLinking {
        position: absolute;
        top: -100px;
        left: 0;
        opacity: 0;
        scroll-margin-top: 95px;
    }
}
  margin-bottom: 30px;
  h2 {
    font-family: 'calibriregular';
  }
  & figure {
    text-align: center;
  }
  & p {
    font-weight: 400;
    margin-bottom: 12px;
    font-size: 16px !important;
    font-family: 'calibriregular';
    color: #000 !important;
    & span{
        font-family: 'calibriregular';
        color: #000 !important;
        font-size: 16px !important;
    }
    & b {
        font-family: 'calibriregular';
        color: #000 !important;
        font-size: 16px !important;
    }
    & i {
        font-family: 'calibriregular';
        color: #000 !important;
        font-size: 16px !important;
    }
}
& div {
    font-weight: 400;
    font-size: 16px !important;
    font-family: 'calibriregular';
    color: #000 !important;
    text-align: justify;
    & span{
        font-family: 'calibriregular';
        color: #000 !important;
        font-size: 16px !important;
    }
    & b {
        font-family: 'calibriregular';
        color: #000 !important;
        font-size: 16px !important;
    }
    & i {
        font-family: 'calibriregular';
        color: #000 !important;
        font-size: 16px !important;
    }
}
  & table {
    width: 100%;
    border: 1px solid #a7a7a7;
    margin-top: 30px;
    margin-bottom: 10px;
    & th {
        font-weight: 700;
        padding: 10px;
    }
    & tr:first-child{
        & td {
            font-weight: 700;
            background: #f1f1f1;
        }
    }
    & td {
        font-weight: 500;
        padding: 6px;
        border-bottom: 1px solid #a7a7a7;
        border-right: 1px solid #a7a7a7;
    }
    & p {
      text-align: left;
      margin: 0 !important;
    }
}
  & h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #ff8300;
    margin-bottom: 5px;
  }
  & p {
    font-weight: 400;
    font-size: 16px;
  }
  &.amendments {
    background: #fff3e5;
    border: 1px solid rgba(22, 112, 146, 0.6);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 20px;
    & h2 {
      color: #009b94;
      & b {
        border: 1px solid #bf1e2e;
        border-radius: 7px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        padding: 4px;
        color: #bf1e2e;
        text-transform: uppercase;
        margin-right: 10px;
      }
      & span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        position: relative;
        margin-left: 36px;
        &::before {
          content: "";
          position: absolute;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background: #a7a7a7;
        }
        & i {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          font-style: normal;
        }
      }
    }
    & p {
      margin-top: 15px;
    }
  }
}
.commanBox.copyInfoMain {
  margin-bottom: 25px;
  padding: 15px 20px;
}
.copyInfo {
  display: flex;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #009B94;
  font-weight: 700;
  & img {
    margin-right: 8px;
  }
}

.adSport {
  background: rgba(0, 155, 148, 0.04);
  border: 1px solid rgba(0, 155, 148, 0.3);
  border-radius: 20px;
  text-align: center;
  min-height: 440px;
  margin-bottom: 20px;
  & .demoImg {
    margin-top: 50px;
  }
}
.commanBox {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(22, 112, 146, 0.5);
  border-radius: 20px;
  width: 100%;
  padding: 20px;
  position: relative;
  &.commanBoxBorder0 {
    border: 0;
  }
}

.asignDropDown {
  display: flex;
  align-items: center;
  justify-content: end;
  & .btn {
    color: #009B94;
    font-weight: 700;
    border: 0;
    padding: 0 10px
  }
  & .dropdown-item {
    color: #009B94;
    font-weight: 700;
  }
}

.simpleTabsNew {
  position: relative;
  & .button {
    position: absolute;
    right: 0;
    top: -6px;

  }
}
.reactSelect__menu {
  border-radius: 10px !important;
  background: #ffffff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08) !important;
}
 
.mudrasBoxRate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pageHeading02 {
  font-size: 16px;
  font-weight: 700;
  color: $highlight;
  line-height: 24px;
  margin: 0;
  & b {
    vertical-align: middle;
    margin-right: 6px;
    height: 25px;
    display: inline-block;
  }
  & i {
    vertical-align: middle;
    font-style: normal;
    font-size: 20px;
  }
}
.inrPrice {
  font-weight: 700;
  font-size: 26px;
}
.inrMudra {
  font-weight: 700;
  font-size: 24px;
}

.previewContent .boxCover.mudrasBox {
  border: 0.5px solid rgba(45, 51, 141, 0.2);
  padding: 15px 20px;
  padding-bottom: 15px !important;
}

.pl-3 {
  padding-left: 15px;
}
.pr-3 {
  padding-right: 15px;
}
.modal-dialog.mdl {
  max-width: 650px;
}
.inrTextInput {
  display: flex;
  align-items: center;
  & i {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-right: 6px;
  }
}
.inrTextLable {
  padding-left: 50px;
}

.accordionWrap-head {
  & a {
    cursor: pointer;
  }
}

.viewHeader {
  background: linear-gradient(270deg, #fef1d4 0%, #faf9e2 100.77%);
  padding: 0 25px;
  & .viewHeaderBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-transform: uppercase;
      color: #009b94;
    }
    & span {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-transform: uppercase;
      color: #009b94;
      cursor: pointer;
    }
  }
}
.viewArtileReportWrap {
  margin: 0 auto;
  max-width: 1024px;
  & .viewArtileSummary {
    & h3 {
      display: block;
      font-weight: 700;
      font-size: 20px;
      color: #ff8300;
      text-transform: uppercase;
      border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
      padding: 20px;
      position: relative;
      & .arrowButton {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
      & i {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #404040;
        text-transform: none;
      }
    }
    & ul {
      padding: 20px;
      & li {
        margin-bottom: 30px;
        list-style-type: none;
        & p {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }
        & .inputCheck {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #009b94;
          margin-top: 14px;
        }
        & .inputDetails {
          background: #f3f3f3;
          border-radius: 10px;
          display: block;
          padding: 16px;
          margin-top: 14px;
        }
      }
    }
    & .customSliderWrap {
      width: 60%;
      margin: 0 0 45px 22px;
    }
  }
}

.viewReportsWrap {
}
.reportLayout {
  padding: 0 40px;
  margin-top: 50px;

  & .col-md-6 {
    padding: 0 30px;
  }
  & .reportLayoutLeft {
    & .commanBox {
      margin-bottom: 25px;
      & .contentPreview {
        background: transparent;
        box-shadow: none;
        border: 0;
        border-radius: 0px;
        padding: 0;
        margin-bottom: 0;
      }
    }
    & .contentPreview {
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(22, 112, 146, 0.5);
      border-radius: 20px;
      width: 100%;
      padding: 20px;
      position: relative;
    }
  }
}
.reportLayoutRight {
  padding: 20px;
  border-radius: 20px 0px 0px 20px;
  border: 3px solid #009B94;
  width: 48%;
  position: absolute;
  right: 0;
  top: 165px;
  background: #ffffff;
  & .titlePree {
    text-transform: uppercase;
    color: #ff8300;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 35px;
    margin-top: 15px;
  }
}

.reviewHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 44px;
  background: linear-gradient(270deg, #fef1d4 0%, #faf9e2 100.77%);
  & .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #009b94;
  }
  & .reviewHeadAction {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    & span {
      cursor: pointer;
      color: #404040;
      margin-right: 22px;
    }
    & .button-link-active {
      text-decoration: none;
      margin-right: 22px;
    }
  }
}
.customAccordion textarea.form-control.addiTextatrea {
  min-height: 172px;
}
.customAccordion {
  margin-top: 30px;
  & .labelForm {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
  & .selectBox .reactSelect-container .reactSelect__control {
    height: 50px;
    min-height: 50px;
  }
  &
    .selectBox
    .reactSelect-container
    .reactSelect__control--menu_is_open
    .css-1xc3v61-indicatorContainer {
    transform: rotate(180deg);
  }
  & .selectBox .reactSelect-container .reactSelect__placeholder {
    margin-left: 9px;
    color: #000000;
  }
  & input.fieldForm {
    height: 50px;
    min-height: 50px;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #009b94 !important;
    &::placeholder {
      font-weight: 600 !important;
      font-size: 16px !important;
      color: #404040 !important;
    }
  }
  & textarea.form-control {
    min-height: 100px;
    &::placeholder {
      font-weight: 600 !important;
      font-size: 16px !important;
      color: #404040 !important;
    }
  }
  & .form-control {
    height: 50px;
    min-height: 50px;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #009b94 !important;
    background: #ffffff !important;
    &::placeholder {
      font-weight: 600 !important;
      font-size: 16px !important;
      color: #404040 !important;
    }
    &:read-only{background: #f3f3f3 !important;}
  }
  & .reactSelect-container .reactSelect__value-container {
    font-size: 16px;
  }
  & .accordion-flush .accordion-collapse {
  }
  & .accordion-flush .accordion-item {
    margin-bottom: 20px;
    border: 0.5px solid rgba(22, 112, 146, 0.5) !important;
    border-radius: 12px;
    overflow: visible;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    & .accordion-button:not(.collapsed) {
      background: transparent;
      border-radius: 11px 11px 0 0 !important;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  & .accordion-body {
    background: #fcfcfc;
    border-radius: 11px;
    position: relative;
    & .customCheck {
      & i {
        background: #009B94;
      }
      & b {
        color: #404040;
      }
    }
    & sup {
      font-size: 18px !important;
      font-weight: 600;
    }
  }
  & .accordion-header {
    & .accordion-button {
      justify-content: space-between;
      border-radius: 11px !important;
      &:focus {
        box-shadow: none;
      }
      &.collapsed {
        & p {
          color: #009B94;
        }
      }
      & p {
        font-weight: 700;
        font-size: 20px;
        color: #ff8300;
        width: calc(100% - 100px);
        text-transform: uppercase;
        & span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          color: #404040;
        }
      }
      & b {
        font-weight: 600;
        font-size: 16px;
        color: #404040;
        white-space: nowrap;
        text-transform: none;
        &.completed {
          color: #009b94;
        }
      }
      &::after {
        background-color: #009B94;
        // background-image: url(../img/icons/downarrow.svg);
        background-image: url(../../img/icons/downarrow.svg);
        border: 1.5px solid transparent;
        box-shadow: none;
        border-radius: 6px;
        width: 30px;
        height: 28px;
        background-position: center;
        margin-left: 16px;
        background-size: 15px;
      }
    }
  }
}

.customTool {
  display: inline-block;
  height: 16px;
}


.popoverToolWrap {
  & h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #BF1E2E;
  }
  & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 15px;
      &:first-child{
          margin-bottom: 0;
      }
      & .red-text {
        color: #BF1E2E;
    }
  }
  & ol,
  & ul {
      margin-left: 18px;
  }
  & li {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 10px;
  }
  & .button-link {
      text-transform: none !important;
  }
}
.vsTable-main thead th:last-child {
  text-align: left;
}
.vsTable-main tbody td:last-child {
  & .text-link {
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.assignRight {
  width: 60%;
  margin: 0 auto;
  & li {
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding-left: 15px;
    margin: 3px 0;




    /* Secondary */

    color: #2d338d;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #2d338d;

    }
  }
}
.addQuestionWrap {
  border-top: 0.5px solid rgba(22, 112, 146, 0.5);
  & .styledRadio input:checked + span::before {
 
  }
  & .styledRadio input:checked + span:after {
    background: #ff8300;
  }

& .questionFeedBack {
  & .form-control {
     border: 1px solid #858585;
      border-radius: 10px;
      background: #ffffff;
      resize: none;
      padding: 15px;
      margin-bottom: 22px;
      &:read-only{
          background: #F3F3F3;
          border: none;
      }
  }
  & p {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 16px;
  }
  & span {
      display: block;
      background: #F3F3F3;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 22px;
  }
 
}

& .editorCommaintWrap {
  & .editorCommaint {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 0.5px solid rgba(167, 167, 167, 0.5);
      &:last-child{
          border-bottom: 0;
      }
      & span {
          display: block;
          background: #F3F3F3;
          border-radius: 10px;
          padding: 15px;
      }
  }
}
& .addQuestionTable {
  margin-bottom: 20px;
  & table{
      width: 100%;
      & tr {
          & th{
              color: #FF8300;
              padding: 20px 10px;
              &:last-child{
                  padding-left: 0;
              }
          }
          & td {
              padding: 20px 10px;
              &:last-child{
                  padding-left: 0;
              }
              & .questionTd {
                  max-width: 245px;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 23px;

              }
              & .customRadio {
                  position: relative;
                  top: -10px; 
                  & b {
                      width: 20px;
                      height: 20px;
                  }
                  & input:checked + b::before {
                      width: 12px;
                      height: 12px;
                      background-color: #FF8300;
                  }
              }
          }
      }
  }
}
& .recomArt {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  & .customRadio {
      display: block;
      margin-bottom: 20px;
  }
 
}
}
.commanTitle02 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgb(255, 131, 0);
  margin-bottom: 16px;
}
.authorDetailsPopup .authorDetails {
  background: rgba(0,155,148,.05);
  border-radius: 20px;
  margin-bottom: 24px;
  padding: 20px;
}
.authorDetailsPopup .authorDetails .authorName {
  color: #404040;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 10px;
}
.authorDetailsPopup .authorDetails .authorName i {
  border: 0.5px solid #404040;
  border-radius: 50%;
  display: inline-block;
  height: 45px;
  margin-right: 10px;
  vertical-align: middle;
  width: 45px;
  background-size: cover; 
}
.authorDetailsPopup .authorDetails p {
  color: #000;
}
.authorDetailsPopup .authorDetails .authorDetailsTag {
  border-top: 0.5px solid #a7a7a7;
  margin-top: 15px;
  padding-top: 12px;
}
// .publishedList {
//   list-style-type: none;
//   & li{
//     margin: 20px;
//   }
// }
.customDrop {
  & .removeArrow {
      &.dropdown .dropdown-toggle::after {
          display: none;
      }
  } 
  & .dropdown {
      width: 100%;
      &.show > .btn-success.dropdown-toggle{
          background-color: #F6F9FB; 
          border: 0;
           color: $primary;
           box-shadow: none;
           &:focus{
              box-shadow: none;
           }
       }
      & .dropdown-toggle {
          color: #009B94;
          background: #F6F9FB;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          width: 100%;
          
          &:focus{
              box-shadow: none;
           }
          & img {
              margin-right: 8px;
              width: 4px;
          }
          &.show{
              &::after{
                  transform: rotate(180deg);
                  transition: all .5s;
              }
          }
          &::after{
              background: #F6F9FB url(../../img/icons/arrow_down.svg);
              border: 0;
              width: 8px;
              height: 5px;
              transition: all .5s;
          }
      }
      & .dropdown-menu {
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
          border-radius: 10px;
          border: 0;
          padding: 0;
          & .dropdown-item{
              border-bottom: 0.5px solid rgba(151,151,151,0.15);
              padding: 8px 12px;
              &:last-child{
                  border-bottom: 0;
              }
              &.active {
                  background: transparent;
                  color: #009B94;
                  font-weight: 700;
              }
              &:active{
                  background: transparent;
                  color: #009B94;
                  font-weight: 700;
              }
          }
      }
  }
  &.moreCustom{
      & .dropdown {
          &.show > .btn-success.dropdown-toggle{ 
              background: transparent;
          }
          & .dropdown-toggle {
              background: transparent;

              &::after{
                  display: none;
              }
          }
          & .dropdown-menu {
              width: 190px;
              & .dropdown-item{
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 19px;
                  color: #009B94;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  
                  & img {
                      margin-left: 24px;
                  }
              }
          }
      }
  }
  &.sideDrop{
      margin-bottom: 12px;
      & .dropdown {
         & .dropdown-menu {
              width: 100%;
          }
      & .dropdown-toggle {
          justify-content: start;
          padding-top: 10px;
          padding-bottom: 10px;
          background: rgba(22,112,146,0.08);
          &::after {
              position: absolute;
              right: 10px;
             // background: url(./../img/icons/arrow_down.svg);
          }
          }
          &.show > .btn-success.dropdown-toggle{
              background: rgba(22,112,146,0.08);
          }
      }
  }
}
.editNoteWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(167, 167, 167, 0.8);
  padding-bottom: 8px;
  margin-top: 27px;
  margin-bottom: 20px;
  & .noteBox {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #009B94;
      & span {
          font-weight: 600;
          color: #BF1E2E;
          text-transform: uppercase;
          padding-right: 5px;
      }
  }
}

.previewPageWrap  {
  background: #ffffff;
  padding-bottom: 50px;
  margin-top: 92px;
}
.publiseAddHeadMain {
  background: rgba(241, 97, 54, 0.04);
  padding: 20px 0;
  margin-bottom: 20px;
}
.publiseAddHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 44px;
  & .publiseAddBtn {
    & .button {
      margin: 0 30px;
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #F16136;
  }
}

.ml-2 { margin-left: 16px;}
.mr-2 { margin-right: 16px;}
.reasonPopup {
  & .reasonList {
    margin-bottom: 15px;
    text-align: left;
    & b {display: block; margin-left: 10px;}
    & span {
      display: block;
      background: #F9F9FD;
      padding: 10px;
      border-radius: 10px;
    }
  }
}
.peerReportClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & a {
    font-weight: 700;
    text-decoration: none;
  
  }
}
.icon-sort {
  cursor: pointer;
}

.acptitle {
    font-size: 18px;
        color: #F16136;
        font-weight: 700; 
        margin-top: 40px;
        margin-bottom: 20px;
        display: block;
}
.apcWrap {
    background-color: rgba(45, 51, 141, 0.04);
    border: 1px solid rgba(45, 51, 141, 0.2);
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
    & .apcHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      & h2 {
        font-size: 16px;
        color: #F16136;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    & .apcFoot {
      border-top: 1px solid #A7A7A7;
      
      margin-top: 10px;
      padding: 10px 10px 0 10px;
      & ul{
        display: flex;
        align-items: center;
        list-style-type: none;
        & li{
          width: 23%;
          &:last-child{
            width: 110px;
          }
          & h3 {
            font-size: 14px;
            font-weight: 600;
            display: block;
            text-transform: uppercase;
            padding-top: 8px;
          }
          & b {
            font-size: 16px;
            font-weight: 400;
            display: block;
          }
        }
      }
    }
}

.popover {
  border: 3px solid #BF1E2E !important;
  background: #ffffff;
  border-radius: 10px !important;
  max-width: 620px !important;
}
.popover .popover-arrow  { 
  background: url(../../img/icons/tool_red.png);
  width: 27px !important;
  height: 9px !important;
  background-size: cover;
  background-position: 3px center;
}
.popover .popover-arrow::after,
.popover .popover-arrow::before {
  display: none !important;
} 

.publishedList {
  overflow: auto;
    max-height: 43vh;
  & li {
      margin-bottom: 20px;
      list-style-type: none;
      &:last-child {
          margin-bottom: 0;
      }
      & a {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #009B94;
          display:block;
          text-decoration: none;
      }
  }
}
.authorNameTh{
  width: 210px;
}
.white-space-nowrap {
  white-space: nowrap;
}

.width225 {
  width: 225px !important;
}
.width158 {
  width: 158px !important;
}

.createTabsWrap{
  position: relative;
  & .createBtnWrap {
    position: absolute;
    right: 0;
    top: -5px;
    width: auto;
    display: inline-block;
  }
}

.fundingText {
  font-size: 16px;
  font-weight: 700;
  color: #009B94 !important;
  text-decoration: none;
  cursor: pointer;
}
.divider01 {
  border-top: 1px dashed #A7A7A7;
}
.couponsList {
  list-style-type: none;
  & li {
    margin-bottom: 15px;
    position: relative;
    & p {
      color: #000; 
      font-size: 16px; 
      font-weight: 700; 
      text-transform: uppercase;
      & small {
        color: #2D338D;
        display: block;
        font-size: 14px;       
        font-weight: 600;
        text-transform: none;
      }
    }
    & .couponsWrap-head {
      & .offWrap {
        font-weight: 700;
        color: #2D338D;
        font-size: 16px;

      }
    }
    & .couponsWrap-text {
      & small {
        color: #000; 
        font-size: 14px; 
        font-weight: 400;
        & b {
          font-weight: 600;
        }
      }
      & .text-link {
        color: #009B94;
        font-weight: 600;
        font-size: 16px;
      }
    }
    & .deleteLink {
      position: absolute;
      right: -10px;
      top: -10px;
      text-decoration: none;
      cursor: pointer;
      & b {
        background: #404040;
        width: 30px;
        height: 30px;
        background-position: center;
        color: #ffffff;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.styledCheckLink {
  & a { 
    text-decoration: none;
    border-left: 1px solid #009B94;
    color: #009B94;
    font-weight: 600;
    padding-left: 8px;
    margin-left: 8px;
    &:hover {
      color: #009B94;
      font-weight: 600;

    }
  }
}
.textAreaManage{
  min-height: 200px;
}

.radioCheck {

  & input {
    display: none;
  }
  & input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  & b {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 18px;
    background: rgba(0,155,148,0.1);
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  & b:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #009B94;
    border-radius: 10px;
    transition: 0.3s;
  }
  
  & input:checked + b {
    background: rgba(0,155,148,0.1);
  }
  
  & input:checked + b:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
  
  // b:active:after {
  //   width: 40px;
  // }

}
.questionBox {
  & span {
      padding-left: 20px;
      position: relative;
      display: block;
      font-weight: 500;
      font-size: 16px;
      color: #009B94;
      &.colorBlack{
          color: #000000;
      }
      &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 4px; 
          background: url(../../img/icons/errorqoestion.svg);
          width: 16px;
          height: 15px;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: cover; 
      }
  }
  & p {
      margin-top: 16px;
      color: #404040;
      & sup {
          font-size: 18px;
          position: relative;
          top: 0;
      }
  }
}

.somethingMind {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: right;
  padding-top: 6px;
  & a {
      color: #009B94;
      font-weight: 700;
      &:hover{
          color: #009B94;
      }
  }
}
.guidelinesWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 0px 20px;
  &.paymentGuidel {
      margin-bottom: 120px;
      margin-top: 50px;
  }
 & span {
  color: #009B94; 
 }
 & .crossrefWrap {
  display: flex;
  align-items: center;
  & span {
      border-right: 1px solid rgba(167, 167, 167, 0.4);
      padding-right: 12px;
      margin-right: 12px;
      & img {
          margin-right: 0;
      }
  }
  & .guidelText {
      margin-top: 10px;
      display: flex;
      align-items: center;
      & a {
          color: #BF1E2E;
          &:hover{
              color: #BF1E2E;
          }
      }
  }
  
}
  & .toolWrap {
      color: #BF1E2E;
      display: flex;
      align-items: center;
     
  }
  & img { 
      margin-right: 4px;
  }
}
.customAccordion {
  margin-top: 30px;
  & .labelForm {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
  }
  & .selectBox .reactSelect-container .reactSelect__control { 
      height: 50px;
      min-height: 50px; 
  }
  & .selectBox .reactSelect-container .reactSelect__control--menu_is_open .css-1xc3v61-indicatorContainer {
      transform: rotate(180deg);
  }
  & .selectBox .reactSelect-container .reactSelect__placeholder{ 
      margin-left: 9px; 
      color: #000000;
  }
  & input.fieldForm { 
      height: 50px; 
      min-height: 50px; 
      font-weight: 600 !important; 
      font-size: 16px !important; 
      color: #009B94 !important; 
      &::placeholder {
          font-weight: 600 !important; 
          font-size: 16px !important; 
          color: #595959 !important;
      }
  }
  & textarea.form-control {
      min-height: 100px;
      &::placeholder {
          font-weight: 600 !important; 
          font-size: 16px !important; 
          color: #595959 !important;
      }
  }
  & .form-control { 
      height: 50px; 
      min-height: 50px; 
      font-weight: 600 !important; 
      font-size: 16px !important; 
      color: #009B94 !important;
      background: #ffffff !important;
      
      &::placeholder {
          font-weight: 600 !important; 
          font-size: 16px !important; 
          color: #595959 !important;
      }
  
  }
      & .reactSelect-container .reactSelect__value-container {
          font-size: 16px;
      }
      & .accordion-flush .accordion-collapse {}
  & .accordion-flush .accordion-item {
      margin-bottom: 20px;
      border: 0.5px solid rgba(22, 112, 146, 0.5) !important;
      border-radius: 12px;
      overflow: visible;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
      & .accordion-button:not(.collapsed) {
          background: transparent;
          border-radius: 11px 11px 0 0 !important;
       }
       &.disabled{
          opacity: .5;
          pointer-events: none;
       }
  }
  & .accordion-body {
      background: #fcfcfc;
      border-radius: 11px; 
      position: relative;
      & .customCheck {
          & i {
              background: #009B94;
          }
          & b {
              color: #404040;
          }
      }
      & sup {
          font-size: 18px !important;
          font-weight: 600;
      }
      & .selectBox .reactSelect__control {
        background: #ffffff !important;
        border: 1px solid #ced4da !important;
        min-height: 50px !important;
        border-radius: 8px !important;    
    }
    & .selectBox .reactSelect__placeholder {
      font-weight: 700;
      color: #595959;
      font-size: 16px !important;
     }
    
  }
  & .accordion-header {
      
      & .accordion-button {
          justify-content: space-between;
          border-radius: 11px !important;
          &:focus {
              box-shadow: none;
          }
          &.collapsed{
              & p {
              color: #009B94;
              }
          }
          & p {
              font-weight: 700;
              font-size: 20px;
              color: #FF8300;
              width: calc(100% - 100px);
              text-transform: uppercase;
              & span {
                  display: block;
                  font-weight: 400;
                  font-size: 16px;
                  color: #595959;
              }
          }
          & b {
              font-weight: 600;
              font-size: 16px;
              color: #595959;
              white-space: nowrap;
              text-transform: none;
              &.completed{
                  color: #009B94;
              }
          }
          &::after{
              background-color: #009B94;
              background-image: url(../../img/icons/downarrow.svg);
              border: 1.5px solid transparent;
              box-shadow: none;
              border-radius: 6px;
              width: 30px;
              height: 28px;
              background-position: center;
              margin-left: 16px;
              background-size: 15px;
          }
          
      }
     
  }
}

.recommendBoxTit {
  margin-bottom: 40px;
}
.redingFilterWrap {
  margin-top: 40px;
}
.redingFilterArea {
  display: flex;
  align-items: center;
  padding-top: 20px;
  & .redingFilterList { 
      width: calc(25% - 50px);
      & .reactSelect-container .reactSelect__placeholder {
          color: #404040;
          font-size: 16px;
      }
      & .fieldWrap {
          width: 100%;
          padding-right: 14px;
          & .ant-select {
              width: 100%;
          }
          & label {
              position: absolute;
              top: -22px;
          }
          & .ant-collapse-header-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: calc(100% - 23px);
              font-size: 16px;
          }
          & .ant-collapse-item {
              padding: 5px 15px;
          }
          & .ant-collapse:has(input[type=checkbox]:checked) .ant-collapse-item {
              padding: 9px 12px 9px 12px !important;
          }
          & .ant-menu-item{
              padding: 7px 0;
              margin: 0;
              white-space: break-spaces;
              line-height: normal;
              &:hover {
                  background: transparent;
              }
          }
          & .ant-menu-item-selected {
              background-color: transparent !important;
          }
           
          & input[type=checkbox]::after { 
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 2px;
              border: 1px solid #848484;
              position: absolute;
              display: none;
          }
          & :where(.css-dev-only-do-not-override-yp8pcc).ant-menu-vertical >.ant-menu-item {
              height: auto;
          }
          & .ant-collapse-content-box {
              width: 370px;
          }
          & .ant-collapse-content-box .ant-menu-title-content input[type=checkbox]:checked::before{
              top: 50%;
              transform: translate(0px, -8px); 
          }
      }
      &.actionWrap{
          width: 194px;
          position: absolute;
          right: 20px;
          & .filterText{
              display: block;
              text-align: center;
              position: absolute; 
              top: -20px;
              width: 100%;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #EF6209;
          }
          & .button  {
              padding: 5px 10px;
          }
          & b {
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              margin-right: 30px;
              position: relative;
              cursor: pointer;
              text-transform: uppercase;
              &::before{
                  content: '';
                  width: 1px;
                  height: 14px;
                  background: #000;
                  top: 4px;
                  right: -13px;
                  position: absolute;
                  opacity: .7;
                  
              }
          }
      }
  }
}

 
.customAccordion textarea.form-control.addiTextatrea {
  min-height: 172px ;
}
.selectBox {
  position: relative;
}
.journalWrapTab {
  border-top: 0.5px solid rgba(22, 112, 146, 0.5);
  padding-top: 40px;
  margin-top: 40px;
  & .commanTitle02 {
      margin-bottom: 30px;
      display: block;
  }
  & .fieldWrap {
      margin-bottom: 40px;
  }
}
.tabCenter {
  padding: 10px 20px 0 20px;
  min-height: 70px;

  
}

.aboutJour {
  text-align: right;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #009B94; 
  & a {
      padding-right: 20px;
      color: #BF1E2E;
      text-decoration: underline;
      &:hover{
          color: #BF1E2E;
      }
  }
 
}

.contentReview {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  margin-top: 10px;
}

.regardingChkBox { 
  background: #FFFFFF;
  border: 0.5px solid rgba(22, 112, 146, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 155, 148, 0.13);
  border-radius: 10px;
  padding: 16px;
  &.active {
      & .regardingRadio label b {
          color: #009B94;
      }
      & .regardingRadio big {
          background: rgba(241, 97, 54, 0.05);
          border: 0.5px solid #FF8300;
          color: #FF8300;
      }
      & .tollArea{
          // background: rgb(226 243 243);
          border: 0.5px solid #009B94;
          &:before {
              background: url(../../img/icons/tool-active.png)
          }
      }
      
     & .priceReview {
          background-image: linear-gradient(to right, #e46333, #ee762d, #f68927, #fb9c21, #ffb01b);
          border: 0;
          padding: 3px 3px 3.2px 3.2px;
          border-radius: 13px;
          & ul {
              background: #ffffff;
              & li {
                   
                  &::before {
                      border-bottom: 0.5px dashed rgba(191, 30, 46, 0.34);
                  }
                  &:last-child{
                      
                      & .priceReviewImg {
                          color: #FF8300;
                      }
                  }
                  & .priceReviewRate {                        
                      color: #16ACE4;
                      & del {
                          position: relative; 
                          text-decoration: none;
                          &::before {
                              content: '';
                              position: absolute;
                              left: -4px;
                              top: 50%;
                              transform: translateY(-50%);
                              width: calc(100% + 8px);
                              height: 2px;
                              background:  #BF1E2E;
                          }
                      }
                      & big {
                     
                          color: #FF8300;
                          padding-right: 0;
                         
                      }
                  }
                  & .priceReviewImg {
                    
                      color: #009B94;
                      & i {
                          
                          // background-color: rgba(0, 155, 148, 0.11);
                         
                          &.priceIcon{
                              background-image: url(../../img/icons/listprice.svg);
                          }
                          &.saveIcon{
                              background-image: url(../../img/icons/save.svg);
                          }
                          &.roducIcon{
                              background-image: url(../../img/icons/reduse.svg);
                          }
                      }
                      
                  }
                  &.saveprice {
                      color: #BF1E2E; 
                      & .priceReviewRate {
                          color: #009B94; 
                      }
                  }
              }
          }
      }
  }
  & .form-check {
      & label{
          text-transform: uppercase;
          font-weight: 700 !important;
          font-size: 18px !important;
          line-height: 22px;
          position: relative;
          top: -3px;
      }
  }
  
}

.regardingRadio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;
  & label {
      
      position: relative;
      margin-bottom: 0;
      font-weight: 600 !important;
      line-height: 20px;
      & label {
          padding-left: 7px;
          
      }
      & input {
          display: none;
      }
      & input:checked + i::before {
          content: '';
          position: absolute;
          left: 3px;
          top: 3px;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #009B94;
      }
      & input:checked + i + b { 
          color: #009B94;
      }
      & i {
          position: absolute;
          left: 0;
          top: 3px;
          width: 15px;
          height: 15px;
          background: #A7C8D5;
          border-radius: 50%;
          z-index: 0;
      }
      & b {
          display: inline-block;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
      }
      & span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $color_gray;
          font-style: italic;
      }
  }
  & big {
      background: rgba(64, 64, 64, 0.06);
      border: 0.5px solid $color_gray;
      border-radius: 5px;
      padding: 3px 10px;
      font-weight: 700;
      font-size: 18px;
  }
  & .custom_radio {
      position: relative;
      z-index: 1;
      left: -23px;
  }
  & .custom_radio .form-check-input[type=radio]{
      background: url(../../img/icons/radio.png);
      background-position: -3px -2px !important;
      background-size: 19px;
  }
  & .custom_radio .form-check-input:checked[type=radio] {
      background: url(../../img/icons/radio-check.png) !important;
      position: relative;
      top: 0px;
      background-position: -1px -1px !important;
      background-size: 18px !important;
      
  }
}
.tollArea {
  background: #F4F4F4;
  border: 0.5px solid $color_gray;
  
  font-weight: 400;
  font-size: 16px;
  color: $color_black;
  margin-top: 20px;
  position: relative;
  padding: 12px;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  &::before {
      content: '';
      position: absolute;
      top: -10px;
          left: 40px;
      width: 31px;
      height: 15px;
      background: url(../../img/icons/tool_default.png);
  }
  &.active{
      background: rgba(0, 155, 148, 0.06);
      border: 0.5px solid #009B94;
      &::before {
          background: url(../../img/icons/tool-active.png)
      }
  }
}

.paymentSuccessWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 0.5px solid #009B94;
  border-radius: 10px;
  margin-top: 50px;
  & .paymentSuccessText {
      font-weight: 600;
      font-size: 16.5px;
      line-height: 20px;
      color: #009B94;
  }
  & .paymentSuccessTool {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px; 
      color: #009B94;
      cursor: pointer;
  }
}
.warningText {
  background: #FFFFFF;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #009B94;
  border: 0.5px solid #BF1E2E;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  & span {
      color: #BF1E2E;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin-right: 5px;
  }
}

.editorHeadMain {
  width: 100%;
  display: inline-block;
  padding: 15px 44px;
  background: linear-gradient(270deg, #FEF1D4 0%, #FAF9E2 100.77%);
  & .editorHead {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & h2{
          color: #009B94;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
          text-transform: uppercase;
      }
      
  }
}

.editorAction {
  & a {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;  
      position: relative;
      text-decoration: none; 
      margin-left: 28px;
      display: inline-block;
      cursor: pointer;  
      &:hover {
          color: #009B94 !important; 
      }
      &:first-child{
          margin-left: 0;                    
      }
      &:last-child{
              
          &::before{
              display: none;
          }                
      }
      &::before{
          content: '';
          position: absolute;
          right: -14px;
          top: 2px;
          background: $color_gray;
          width: 1px;
          height: 17px;
      }
      &.active {
          color: $color_gray;
          opacity: 1;
          &:after {
              content: "";
              width: 34px;
              height: 8px;
              border-radius: 8px;
              background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
              display: block;
              position: absolute;
              left: 0;
              bottom: -13px;
              z-index: 1;
          }
      }
  }
}

.registrationPaymentMain {
  padding: 30px 44px; 
  max-width: 1184px;
  margin: 0 auto;
  & .paymentCheck {
      padding: 20px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .customCheck {
          margin-bottom: 10px;
         width: calc(100% - 180px) ;
         & i {
          background: #009B94;
          top: 2px;
         }
         & b {
          color: #404040;
         }
      }
  }
  & .registrationPaymentCard {
      background: #FFFFFF;
      border: 0.5px solid rgba(22, 112, 146, 0.5);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      & .registrationPaymentHead {
          padding: 14px 25px;
          border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
          & h3{
              font-weight: 700;
              font-size: 20px;
              color: #FF8300;
              margin: 0;
              text-transform: uppercase;
              padding: 20px 0;
          }
          & p {
              font-weight: 400;
              font-size: 16px;
              color: #404040;
              margin: 0;
          }
          & .articleSuccess {
              padding: 20px 0;
              text-transform: uppercase;
              color: #009B94;
              font-weight: 700;
              font-size: 20px;
          }
      }
      & .registrationPaymentCotts {
          padding: 14px 25px;
          border-bottom: 0.5px solid rgba(22, 112, 146, 0.5);
      }
      & .registrationMidd {
          background: #FCFCFC;
          padding: 10px 24px;
          display: inline-flex;
          width: 100%;
          border-radius: 12px;
          & .registrationMiddLeft {
             display: inline-block;
             vertical-align: top;
             width: 100%;
              & h4 {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 19px;
                  color: #FF8300;
                  text-transform: uppercase;
                  margin-top: 20px;
              }
              & ul {
                  padding: 0;
                  list-style-type: none;
                  & li {
                      padding: 14px 0;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      position: relative;
                      &:before {
                          content: '';
                          background: url(../../img/icons/border.png);
                          width: 100%;
                          height: 1px;
                          position: absolute;
                          left: 0;
                          bottom: 0;
                      }
                      &:last-child{
                          &:before {
                              display: none;
                          }
                      }
                      &.grandTotalList{
                          display: block;
                      }
                      & .grantApplied {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          color: #16ACE4; 
                          padding: 6px 0;
                          & b {font-weight: 600;}
                          & span {font-weight: 600;}
                      }
                      & .subtotalPrice {
                          & .mudra {
                              line-height: 17px;
                              display: inline-block;
                          }
                      }
                      & .subTitle {
                          font-weight: 600;
                          color: #009B94;
                          font-size: 18px;
                          line-height: 22px;
                      }
                      & .listCardBox {
                          display: inline-block;
                          width: 100%;
                          & .listCard {
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              margin-bottom: 14px;
                              &:last-child{
                                  margin-bottom: 0;
                              }
                              & span {
                                  font-weight: 600;
                                  color: #404040;
                              }
                              & b {
                                  font-weight: 500;
                              }
                              & a {
                                  font-weight: 700;
                                  font-size: 16px;
                                  line-height: 19px;
                                  color: #009B94;
                                  text-transform: uppercase;
                                  text-decoration: none;
                                  &:hover{
                                      color: #009B94;
                                  }
                              }
                          }
                      }
                      & .grandCardBox {
                          width: 100%;
                          & .grandCardList {
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              margin-bottom: 10px;
                              &:last-child{
                                  margin-bottom: 0;
                              }
                              & .errorText {
                                  color: #BF1E2E;
                                  font-weight: 700;
                              }
                          }
                      }
                  }
              }
          }
          & .registrationMiddRight {
              display: inline-block;
              width: 100%;
              margin-left: 24px;
              position: relative;
              vertical-align: top;
              padding-left: 20px;
              &::before{
                  content: '';
                  background: rgba(22, 112, 146, 0.5);
                  width: 1px;
                  height: calc(100% + 22px);
                  position: absolute;
                  left: 0;
                  top: -11px;
              }
              & ul {
                  list-style-type: none;
                  padding-left: 0;
                  & li {
                      padding: 14px 0;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      position: relative;
                      &:before {
                          content: '';
                          background: url(../../img/icons/border.png);
                          width: 100%;
                          height: 1px;
                          position: absolute;
                          left: 0;
                          bottom: 0;
                      }
                      &:last-child{
                          &:before {
                              display: none;
                          }
                      }
                      & .listCardBox {
                          display: inline-block;
                          width: 100%;
                          & .listCard {
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              margin-bottom: 14px;
                              &:last-child{
                                  margin-bottom: 0;
                              }
                              & span {
                                  font-weight: 600;
                                  color: #404040;
                              }
                              & b {
                                  font-weight: 500;
                              }
                              & a {
                                  font-weight: 700;
                                  font-size: 16px;
                                  line-height: 19px;
                                  color: #009B94;
                                  text-transform: uppercase;
                                  text-decoration: none;
                                  &:hover{
                                      color: #009B94;
                                  }
                              }
                              & .applyInput {
                                  display: flex;
                                  align-items: center;
                                  & input {
                                      border: 0.5px solid #A7A7AA;
                                      font-size: 16px;
                                      font-weight: 400;
                                      border-radius: 8px;
                                      padding: 4px 10px;
                                      width: 200px;
                                      margin-right: 10px;
                                  }
                              }
                          }
                      }
                      & .successText {
                          color: #4CAF50;
                          font-weight: 500;
                          & b {
                              font-weight: 700;
                          }
                          & img {
                              margin-right: 5px;
                          }
                      }
                  }
              }
              & .offerArea {
                  padding-left: 24px;
                  & .offerAreaTitle {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 19px;
                  }
                  & .offerAreaValue {
                      & a {
                          font-weight: 700;
                          font-size: 16px;
                          line-height: 19px;
                          margin-left: 15px;
                          text-decoration: none;
                      }
                      & input {
                          background: #FFFFFF;
                          border: 0.5px solid #A7A7AA;
                          border-radius: 8px;
                          height: 38px;
                          padding: 0 12px;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 19px;
                          width: 152px;
                          &::placeholder{
                              font-size: 16px;
                          }
                      }
                  }
              }
          }
      }
  }
}

.priceReview {
  display: block;
  border-radius: 10px;      
  margin-top: 22px;
  width: 92%;
  margin: 18px auto;
  position: relative;
  background: rgba(255, 255, 255, 0.13);
  z-index: 0;
  border: 3px solid rgba(64, 64, 64, 0.8);
  
  & ul {
      background: rgba(64, 64, 64, 0.02);
      position: relative;
      z-index: 1;
      border-radius: 10px;
      & li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          position: relative;
          font-size: 16px;
        
          &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 2%;
              width: 96%;
              border-bottom: 0.5px dashed rgba(191, 30, 46, 0.34);
          }
          &:last-child{ 
              &::before {
                  display: none;
              }
          }
          & .priceReviewRate {
              font-weight: 800;
              font-size: 25px;
              line-height: 19px;
              color: #a4a4a4;
              & big {                    
                  border-radius: 5px;
                  color: #a4a4a4;
                  font-weight: 800;
                  padding: 6px 0px 6px 8px;
                  display: inline-flex;
                  font-size: 28px;
              }
              & del {
                  position: relative;
                  text-decoration: none;
                  &::before {
                      content: "";
                      position: absolute;
                      left: -4px;
                      top: 50%;
                      transform: translateY(-50%);
                      width: calc(100% + 8px);
                      height: 2px;
                      background: #404040;
                  }
              }
          }
          & .priceReviewImg { 
              display: flex;
              align-items: center;
              color: #a4a4a4;
              text-transform: uppercase;
              font-weight: 800;
              font-size: 18px;
              & i {
                  display: inline-block;
                  width: 21px;
                  height: 26px;
                  border-radius: 50%; 
                  margin-right: 10px;
                  background-position: center;
                  background-repeat: no-repeat; 
                  &.priceIcon{
                      background-image: url(../../img/icons/listprice_disable.svg);
                  }
                  &.saveIcon{
                      background-image: url(../../img/icons/save_disable.svg);
                      background-size: contain;
                  }
                  &.roducIcon{
                      background-image: url(../../img/icons/reduse_disable.svg);
                  }
              }
              
          }
          &.saveprice {
              color: #a4a4a4;
              font-weight: 600;
              & .priceReviewRate {
                  color: #a4a4a4;
                  font-weight: 800;
              }
          }
      }
  }
}

.reviewerWrap {
  border-top: 0.5px solid rgba(22, 112, 146, 0.5);
  margin-top: 30px;
  padding-top: 40px;
  & .commanTitle02 {
      margin-bottom: 15px;
      display: block;
  }
  & .customCheck {
      margin-bottom: 25px;
  }
}
.mandatorySup {  color: #336d91 !important; }
.reviewerFormArea {
  padding: 0 40px 25px 40px;
  position: relative;
  display: inline-block;
  width: 100%;
  &::before{
      content: '';
      width: 100%;
      height: 1px;
      background: url(../../img/icons/border.png);
      position: absolute;
      left: 0;
      bottom: 16px;

  }
  &:last-child {
    &::before{
      display: none;
    }
  }
  & textarea.form-control {
      border: 1px solid #858585 !important;
      border-radius: 10px;
      min-height: 100px; 
      &::placeholder {
          opacity: 0.4;
      }
  }
}

.reviewerFormWrap {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 12px;
  & .reviewerFormLeft {
      width: 48%;
      &.reviewerTitle {
          display: flex;
          & .reviewerFormLeftBox{
              width: 140px;
              padding-right: 15px;
          }
          & .reviewerFormRightBox {
              width: 100%;
          }
      }
  }
  & .reviewerFormRight {
      width: 48%;
  }

  & .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
      // background: #F3F3F3 !important;
      // border-color: #F3F3F3 !important;
      pointer-events: none; 
  }
  & .reactSelect-container.reactSelect--is-disabled .reactSelect__dropdown-indicator { 
    display: none;
   }
  & .custom_input input:read-only {
      // background: #F3F3F3 !important;
      // border-color: #F3F3F3 !important;
      pointer-events: none; 
      &::placeholder{
          opacity: 0.4;
      }
  }
}
.reviewerFormArea textarea.form-control[readonly]
{
  pointer-events: none; 
  background-color: #F3F3F3;
}

.suggestWrap { 
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $color1;
  margin-left: 40px;
  & a {
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
          color: #009B94;
      }
  }
}

.customCheck {
  position: relative;
  padding-left: 20px;
  margin-bottom: 0;
  cursor: pointer;
  & input {
      display: none;
  }
  & input[type="checkbox"]:checked + i { background: #009B94 url("../../img/icons/active-check_blue.svg");}
  & input[type="checkbox"]:checked + i + b {color: #009B94;}
  & i {
      position: absolute;
      left: 0;
      top: 4px;
      width: 15px;
      height: 15px;
      display: inline-block;
      background: rgba(64, 64, 64, 0.5);
      border-radius: 2px;

  }
  & b {
      font-weight: 600;
      font-size: 16px;
      color: #009B94;  
      line-height: 135%;
  }
}


.contributorsBody {
  & .contributorsTitle { 
      display: flex;
      align-items: center;
      justify-content: space-between; 
      &.secondryAuthor {
          margin-top: 40px;
          padding-top: 40px;
          position: relative;
          &::before{
              content: ''; 
              background: url(../../img/icons/border.png); 
              height: 1px;
              width: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
          }
      }
      & h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          color: $color2;
      }
      & span {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: $color1;
      }
      & .customCheck b {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $color1;
      }
  }
  & .contributorsDetails {
      & .contributorsDetailsLeft {
          width: 50%;
          display: inline-block;
          vertical-align: top;
          padding: 0 20px;
          & .contributorsFirst {
              display: flex;
              & .defaultInput {
                  margin-right: 15px;
                  &.title{
                      width: 150px;
                  }
                  &:last-child {
                      margin-right: 0;
                  }
              }
          }
      }
      & .contributorsDetailsRight {
          width: 50%;
          display: inline-block;
          vertical-align: top;
          padding: 0 20px;
      }
  }
}

.statementWrap {
  
  margin-top: 50px; 
  margin-left: -20px;
  padding: 40px 40px 0 40px;
  width: calc(100% + 40px);
  position: relative;
  &::before{
      content: '';
      background: rgba(22, 112, 146, 0.5);
      height: 1px;
      width: 98%;
      position: absolute;
      left: 1%;
      top: 0;
      display: block;
  }
  & .defaultInput {
      max-width: 49%;
      & label {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
      & textarea {
          height: 110px;
          resize: none;
      }
  }
  & .guidelinesWrap {
      margin-top: 25px;
  }
}

.commanTitle02 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: $color2;
  margin-bottom: 16px;
}
.fundingInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
  & .funderContent {
      font-weight: 400;
      font-size: 16px;
      color: $color_gray;
      width: 100%;
      padding-right: 45px;
  }
  & .funderTextBoxArea {
      width: 100%;
      margin-left: 70px;
  }
  & .defaultFeild {
      width: 100%;
      
      border: 0.5px solid #989898;
      border-radius: 10px;
      padding: 15px;
      color: #009B94;
      &:read-only{ 
          background: #F3F3F3 !important;
          border-color: #F3F3F3 !important;
      }
  }
}

.funderWrap {
  margin-top: 30px;
  padding-top: 30px;
  position: relative;
  &::before{
      content: '';
      width: 100%;
      height: 1px;
      background: url(../../img/icons/border.png);
      position: absolute;
      left: 0;
      top: 0;
  }
  & .commanTitle02 {
      margin-bottom: 30px;
      margin-top: 0px;

  }
  & .primaryFunder {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 0 20px;
      & .primaryFunderLeft {
          width: 50%;
          display: inline-block;
          padding-right: 45px;
          vertical-align: top;
      }
      & .primaryFunderRight {
          width: 50%;
          display: inline-block;
          padding-left: 45px;
          vertical-align: top;
      }
  }
}

.authorWrapTitle {
  display: flex;
  align-items: center;
  & .customCheck {
      & i {
          
      }
      & input:checked {
          & i + b {
              color: #009B94 !important;
          }
      }
      & b {
          color: #404040 !important;
      }
  } 
  & .helpText {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #009B94;
      margin-left: 35px;
      position: relative;
      &::before {
          content: '';
          position: absolute;
          left: -16px;
          top: 2px;
          background: #A7A7A7;
          width: 1px;
          height: 12px;
      }
  }
  & .customTool {
      position: relative;
      top: -3px;
  }
}

.defaultInput {
  margin-top: 24px;
}

.supStar { 
  font-size: 18px;
  position: relative;
  top: 0;
  color: #BE1E2D !important;
  &.blackStar{
      color: #000000;
  }
}
.recivedCheck {
  margin-top: 20px;
  margin-left: 20px;
}

.statementsWrap {
  display: flex;
  & .statementsLeft {
      width: 100%;
      padding: 30px 40px 40px 20px;
      border-right: 0.5px solid rgba(22, 112, 146, 0.5);

  }
  & .statementsRight {
      width: 100%;
      padding: 30px 20px 0 40px;
      & ul {
          padding-left: 22px;
          padding-top: 15px;
          & li {
              padding: 10px 0;
              & a{
                  font-weight: 600;
                  font-size: 16px;
                  text-decoration: none;
              }
          }
      }
      & .copyright {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #858585;
          padding-left: 7px;
      }

  }
}
.fundingWrap {
  padding: 0 20px;
  & .commanTitle02 {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  & .defaultInput {
      margin-bottom: 50px;
    
  }
  & .defaultFeild::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #404040;
  }
  & .customCheck {
      & i {
          top: 2px;
      }
  }
}
.statements { 
  margin-bottom: 30px;
  & strong {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px;
      display: block;
  }
  & textarea {
      background: #FFFFFF;
      border: 0.5px solid #989898;
      border-radius: 10px;
      padding: 14px;
      width: 100%;
      resize: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      min-height: 110px;
  }
  & .regardingChklist {
      padding-left: 25px;
  }
  & .regardingRadio label b {
      text-transform: none;
      font-size: 16px;
  }
  & .customCheck i {
      top: 50%;
      transform: translateY(-50%);
  }
}

.customAccordion .selectBox .reactSelect-container .reactSelect__control {
  height: 50px;
  min-height: 50px;
  background: #ffffff !important;
  border-color: #858585 !important;
    border-width: 0.5px;
    border-radius: 10px;
    
    font-weight: 600;
    font-size: 14px;
    color: #97979B;
}
.defaultInput {
  margin-top: 24px;
  & label{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $color_gray;
      display: block;
      width: 100%;
      padding-left: 16px;
  }
  & input {
      background: #FFFFFF;
      border: 0.5px solid #009B94;
      border-radius: 10px;
      padding: 10px 16px;
      display: block;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #009B94;
      &:read-only {
          background: #F3F3F3 !important;
          border-color: #F3F3F3 !important;
      }
  }
  & textarea {
      background: #FFFFFF;
      border: 0.5px solid #989898;
      border-radius: 10px;
      padding: 10px 16px;
      display: block;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $color_gray;
  }
  & .defaultFeild {
      background: #FFFFFF;
      border: 0.5px solid #989898;
      border-radius: 10px;
      padding: 14px 16px;
      
  }
  & .py-2 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
  & .hidden{
      display: none;
  }
}
.inrBox {
  margin-top: 24px;
  display: flex;
   & i {
      display: inline-block;
      vertical-align: middle;
      font-style: normal;
      margin-top: 35px;
   }
   & .defaultInput {
      margin-top: 0;
      display: inline-block;
      margin-left: 16px;
      width: calc(100% - 40px);
   }
   
}
.defaultInput .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
          background: #F3F3F3 !important;
          border-color: #F3F3F3 !important;
}
.selectBox .reactSelect-container.reactSelect--is-disabled .reactSelect__control {
  background: #F3F3F3 !important;
  border-color: transparent !important;
}

.customRadio {
  position: relative;
  padding-left: 20px;
  & input {
      display: none;
  }
  & input:checked + b + span {
      color: #009B94;
  }
  & input:checked + b::before{
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }
  & b {
      background: #A7C8D5;
      width: 12px;
      height: 12px;
      border-radius:50%;
      position: absolute;
      left: 0;
  }
  & span {
      font-weight: 700 !important;
      font-size: 18px;
      text-transform: uppercase; 
      line-height: 22px;
      position: relative;
      font-style: normal !important;
      text-transform: none !important;
      top: -3px;

  }
}

.statementsGuide {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
}
.commitPostWrap {
  & .commitPostArea {
      & .commitPostAreaHead {
          border-radius: 10px;
          background: rgba(241, 97, 54, 0.05);
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          padding: 14px 20px;
          & h2{
              font-size: 20px;
              color: #FF8300;
              font-weight: 700;
              text-transform: uppercase;
              margin-bottom: 0;
              & p {
                  font-weight: 400;
                  display: block;
                  margin-bottom: 0;
                  font-style: italic;
                  color: #000;
                  font-size: 16px;
                  text-transform: none;
              }
          }
          & h3{
              font-size: 16px;
              color: #FF8300;
              font-weight: 700;
              text-transform: uppercase;
              margin-bottom: 0;
              & span {
                  color: #16ACE4;
              }
          }
      }
      & .commitPostAreaRate {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          & h2 {
              color: #404040;
              font-size: 18px;
              font-weight: 600;
              & p {
                  font-weight: 400;
                  display: block;
                  margin-bottom: 0;
              }
          }
          & .rateHere {
              & i {
                  color: #009B94; 
                  font-size: 18px; 
                  font-weight: 600; 
                  display: inline-block;
                  font-style: normal;
              }
              & span {
                  & b {
                      display: inline-flex;
                      width: 30px;
                      height: 30px;
                      align-items: center;
                      justify-content: center;
                      border-radius: 50%;
                      background: rgba(22,112,146,0.3);
                      font-weight: 600;
                      margin: 0 5px;
                      color: #009B94;
                      cursor: pointer;
                      &.active {
                          background: #009B94;
                          color: #ffffff;
                      }
                  }
              }
          }
      }
      & .commitPostAreaText {
          & textarea {
              border: 1px solid #989898;
              padding: 15px;
              border-radius: 10px;
              width: 100%;
              resize: none;
              min-height: 150px;
          }
      }
      & .commitPostAreaBtn {
          margin-top: 15px;
      }
  }
  & .commitShow {
      border: 3px solid rgba(255, 131, 0, 0.50);
      border-radius: 20px;
      padding: 25px 30px;
      margin-top: 25px;
      & .commitShowRepeat {
          border-bottom: 1px solid rgba(64,64,64,0.2);
          padding-bottom: 24px;
          margin-bottom: 24px;
          &:last-child{
              border-bottom: 0;
              margin-bottom: 0;
          }
      }
      & .commitShowHead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          & .commitShowHeadUser{
              display: inline-flex;
              align-items: center;
              & figure {
                  width: 35px;
                  height: 35px;
                  overflow: hidden;
                  border-radius: 50%;
                  background-size: contain !important;
                  background-position: center;
                  background-color: #f1f1f1;
                  margin-bottom: 0;

              }
              & b {
                  color: #404040;
                  font-weight: 600; 
                  margin-left: 15px;
              }
              & span {
                  color: #000000;
                  position: relative;
                  padding: 0 38px;
                  display: inline-block;
                  &::before{
                      content: '';
                      position: absolute;
                     
                      top: 50%;
                      transform: translateY(-50%);
                      background: #A7A7A7;
                      width: 1px;
                      height: 12px;
                      left: 18px;

                  }
                  &::after{
                      content: '';
                      position: absolute;
                    
                      top: 50%;
                      transform: translateY(-50%);
                      background: #A7A7A7;
                      width: 1px;
                      height: 12px;
                      right: 18px;
                      display: none;

                  }
              }
              & small {
                  color: #009B94;
                  font-size: 16px;
                  font-weight: 600;
                  & i {
                      width: 20px;
                      height: 20px;
                      display: inline-flex;
                       align-items: center;
                       justify-content: center;
                       background: #009B94;
                       color: #ffffff;
                       font-size: 14px;
                       font-style: normal;
                       border-radius: 50%;
                       font-weight: 400;
                  }
              }
          }
          
          & .flogCommit {
            display: flex;
            & .flagged-text {
              color: #BF1E2E; 
              font-size: 14px; 
              font-weight: 400; 
              border: 1px solid #BF1E2E;
              padding: 2px 6px;
              border-radius: 4px;
              margin-right: 12px;
            }
              & b {
                  width: 28px;
                  height: 28px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  background: url(../../img/icons/delete-icon.svg); 
                  background-repeat: no-repeat;
                  border-radius: 50%;
                  cursor: pointer;
              }
          }
      }
  }
}

.floagApproveBox {
  border-radius: 20px;
  background: rgba(191, 30, 46, 0.04);
  padding: 18px 30px;
  margin-top: 20px;
  & .commitShowHead{
     & .commitShowHeadUser {

     }
     & .button {
      min-width: 120px;
      margin-left: 20px;
     }
  }
  & .flaggedReson {
    border-top: 1px solid #A7A7A7;
    padding-top: 21px;
    margin-top: 15px;
    & span {

    }
    & a {
      color: #009B94; 
      font-size: 16px; 
      font-weight: 600; 
    }
  }
}
.userAuthorTh {
  width: 120px;
}

 
.mudrasBoxHeight {
  min-height: 122px;
}
.userDraftTh { width: 155px;}
.userDeactivatedTh { width: 178px; }
.specialtd { 
  width: 270px;
  display: inline-block;
  & .articleTitleStatus {
    margin-bottom: 10px;
    & .text-link{ width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
    & small{
      display: block;
      font-size: 14px;
    }
  }
}
.agingText {
  font-weight: 600;
}

.inrOffer {
  display: block;
  
  font-size: 600;
}
 
.rightsCheckbox {
  & .styledCheck input:checked + span:after {
    top: 7px;
  }
}
.vsTable-main thead th.revenueTh:last-child {
  text-align: right;
}
.revenueTh {
  width: 140px;

}
.ArticleDraft{
  width: 130px;
}
.RequestsReceivedDraft,
.requestReviewsTh,
.rejectedHeaderTh {
  width: 130px;
}
.underHeaderTh,
.liveHeaderTh,
.offerTh,
.rejectedHeaderThSecond,
.revisionHeaderThSecond {
  width: 110px;
}
.approvedHeaderTh { width: 130px;}
.revisionHeaderTh,
.archiveHeaderTh { width: 130px; }
.journalTh { width: 175px; }
.pl-50 {
  padding-left: 50px !important;
}
.megaHeaderTh { width: 100px; text-align: right !important; }
.megaArticleTh { width: 210px; } 
.megaCratedTh { width: 130px; }
.monthRemove {
  padding-top: 38px;
}
.recommendedActoinTh { width: 200px; text-align: right !important; }
.recommendedDateTh { width: 125px; } 


.formBoxHelf {
  width: 420px;
   
}
.formBox {
  display: flex;
  margin-bottom: 30px;
 
  & .formBoxList {
    width: 100%;
    padding: 0 58px;
    & .error-field {
      font-weight: normal;
    }
    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      padding-right: 0;
    }
    &.formBoxListTitle {
      display: inline-flex;
      justify-content: space-between;
      & .fieldWrap {
        width: calc(100% - 140px);
        display: inline-block;
        &:first-child{
          width: 120px;
          display: inline-block;
        }
      }
    }
  }
}
.setBtnBox { 
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.maxwidthCon {
   max-width: 437px;
}
.labelForm .socityUpload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
  & span { 
    color: #009B94; 
    font-size: 16px; 
    font-weight: 700; 
    opacity: 1; 
    font-style: normal;
  }
  & .accFormat {
    color: #404040;
    font-weight: 400;
    & b {
      font-weight: 600;
      color: #404040;
    }
  }
  & .downloadBtn {
    width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #009B93 url(../../img/icons/downloadfile.svg);
    border: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 3px;
    background-size: 14px;
    margin: 0 0 0 6px;
    position: relative;
    top: 6px;
    &.removeBtn {
      background: #97979B url(../../img/icons/close-w.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

}
.megaImgWrap {
  display: inline-block;
  min-height: 60px;
}
.fileAttachWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    margin-top: 15px;
    overflow: hidden;
  & .fileAttacArea {
    //border: 1.5px dashed #009B94;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background: #f8fcfc;
    border: 1px solid #ddf1f1;
    overflow: hidden;
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(0 155 148)' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    &.aspectRatio465x287{
      aspect-ratio: 465 / 287;
      margin-bottom: 15px;
    }
    & input {
      display: none;
    }
    & span {
      text-align: center;
      & b {
        display: block;
        text-align: center; 
        color: #009B94; 
        font-size: 16px; 
        font-weight: 700;
        margin-top: 12px;
      }
      & p{
        text-align: left;
        font-size: 14px;
        color: #404040;
        
        & i {
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}
.editDiscipline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .text-link{
    opacity: 1;
    font-style: normal;
  }
}
.adsWrap {
  & .fileAttachWrap {
    justify-content: start;
  & .fileAttacArea {
    height: 756px;
    width: 360px;
    overflow: hidden;
  }
}
}
.magaBtn {
  & .text-link {
    margin-left: 25px;
  }
  & .button {
    margin-left: 25px;
  }
}

.customDropWrap {
  & .dropdown-toggle {
    text-decoration: none;
    font-weight: 600;
    color: #009B94;
    border: none;
    padding-left: 0;
  }
  & .dropdown-item {
    text-decoration: none;
    font-weight: 600;
    color: #009B94;
    text-transform: capitalize;
  }
}
.smText {
  font-weight: 400;
}

.staticLink {
  background: #F9F9FD;
  padding: 12px;
  border-radius: 20px;
  max-width: 472px;
  width: 100%;
  & .styledCheck {
    display: block;
    margin: 18px 0;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  } 
  & .styledCheck input:checked + span {
    color: #2D338D;
  }
}

.multiselectBox {
  & .ant-menu-inline >.ant-menu-item, 
  & .ant-menu-vertical >.ant-menu-item, 
  & .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, 
  & .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
 
    height: auto;
    line-height: 22px;
    display: inline-block;
    white-space: normal;
    padding: 0;
  }
  & .ant-collapse-content-box .ant-menu-item-selected {
    background-color: transparent !important;
  }
  & .ant-menu-item {
    & .ant-menu-title-content {
      position: relative;
      padding-left: 21px;
      & input {
        position: absolute;
        left: 0;
        top: 5px;
      }
      & input[type=checkbox]:checked::before {
        top: -2px;
      }
    }
  }
  & .ant-checkbox-group {

  }
}

 
 .reactSelect__placeholder {
  color: #404040;
  font-size: 16px;
}
.reactSelect__control.reactSelect__control--menu_is_open .reactSelect__dropdown-indicator {
  transform: rotate(180deg);
  background: red;
  border: 10px solid red;
}

  .reactSelect__value-container {
  font-size: 16px;
}
 .reactSelect__control {
  background: #ffffff !important;
  border-color: #858585 !important;    
    font-weight: 600;
    font-size: 14px;
    color: #97979B;
}

.asSpotWrap {
  border-top: 1px solid #97979B;
  margin-top: 40px;
  padding-top: 40px;
  &.border0{
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }
}
.asSpotTwoWrap {
  position: relative;
  margin-top: 50px;
  padding-top: 30px;

  &::before{
    content: '';
    background: url(../../img/icons/border.png);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }
  
}

.spaceManage {
  margin-top: 40px;
}
.readMore {
  color: #009B94;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.guidelinesPageWrap  {
  margin-bottom: 15px;
  display: block;
  max-width: 980px;
  margin: 100px auto 80px;
  & h2 {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 600;
      color: #167092;
      text-transform: uppercase;
  }
  & p {
      margin-bottom: 15px;
  }
  & ul,
  & ol {
      margin-bottom: 15px;
      margin-left: 15px;
  }
}
.red-dot {
  position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 0px;
    background: red;
    display :inline-block;
    margin-left: 3px;
    
}
.toolSecond{
  position: relative;
  top: -2px;
}
.reviewArticleReport {
  & p {
      font-weight: 400;
      font-size: 16px !important;
      font-family: 'calibriregular';
      color: #000 !important;
      & span{
          font-family: 'calibriregular';
          color: #000 !important;
          font-size: 16px !important;
      }
      & b {
          font-family: 'calibriregular';
          color: #000 !important;
          font-size: 16px !important;
      }
      & i {
          font-family: 'calibriregular';
          color: #000 !important;
          font-size: 16px !important;
      }
  }
  & div {
      font-weight: 400;
      font-size: 16px !important;
      font-family: 'calibriregular';
      color: #000 !important;
      & span{
          font-family: 'calibriregular';
          color: #000 !important;
          font-size: 16px !important;
      }
      & b {
          font-family: 'calibriregular';
          color: #000 !important;
          font-size: 16px !important;
      }
      & i {
          font-family: 'calibriregular';
          color: #000 !important;
          font-size: 16px !important;
      }
}
}

.updateBoxWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 33px;
  & .updateBoxBtn {
    & b {
      color: #009B94; font-size: 16px; font-weight: 700;
      cursor: pointer;
    }
  }
}

.inputWithMultiple {
  position: relative;

  & .text-link {
    position: absolute;
    right: 15px;
    top: 5px;
    &.orangeText{
      color: #F16136;
    }
    & b {
      font-weight: 700;
    }
    & i {
      font-style: normal;
      padding-left: 12px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        height: 13px;
        width: 1px;
        background: #404040;
        left: 4px;
        top: 2px;
      }
    }
  }
  & .addMultiSubject {
    display: block;
    border-radius: 10px;
    border: 0.5px solid rgba(64, 64, 64, 0.00);
    background: #F9F9FD;
    padding: 10px 12px;
    position: relative;
    left: 0;
    width: 100%;
    z-index: 9;
    margin-top: 8px;
    & .addMultiSubjectHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & span {
        color: #404040; 
        font-size: 14px; 
        font-weight: 400;
      }
      & b {
        color: #97979B; 
        font-size: 16px; 
        font-weight: 600;
        cursor: pointer;
      }
    }
    & .addMultiSubjectBox {
      margin-top: 12px;
      max-height: 320px;
      overflow: auto;
      & .addMultiLisy {
        margin-bottom: 10px;
        & b {
          color: #2D338D; 
          font-size: 16px; 
          font-weight: 600;
          padding-right: 5px;
          vertical-align: middle;

        }
        & i {
          background: url(../../img/icons/closeMulti.svg);
          width: 20px;
          height: 20px;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          top: -2px;
        }
      }
    }
  }
}
textarea.fieldForm.bgNone { background-color: transparent; font-weight: 400; }

.mastheadactionbtn { 
  display: flex;
  align-items: center;
  justify-content: end;
  & b {
    margin-left: 30px;
    position: relative;
    cursor: pointer;
    color: #222;
    &::before{
      content: '';
      position: absolute;
      left: -15px;
      top: 50%;
      background: #97979B;
      width: 1px;
      height: 16px;
      transform: translateY(-50%);

    }
  }
}
.otherWapBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faqSection {
  background-image: url(../../img/banner/banner_bg.png);
  background-position: 50% 0%;
  background-size: contain;
  background-repeat: no-repeat;

  & .containWrap {
      @media #{$desktop} {
          padding: 0 90px;
      }
  }

  p,
  li {
      text-align: left;
  }

  & .faqWrap {

      & h1 {
          font-size: 30px;
          color: $primary;
          font-weight: 400;
          letter-spacing: -2px;
          position: relative;
          padding-left: 58px;
          margin-bottom: 10px;

          @media #{$desktop} {
              font-size: 46px;
              padding-left: 90px;
              margin-bottom: 0;
          }

          &::before {
              content: "";
              width: 50px;
              height: 20px;
              background-image: url('../../img/icons/wave.svg');
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              position: absolute;
              left: 0;
              top: 10px;

              @media #{$tablet} {
                  width: 80px;
                  height: 28px;
                  top: 16px;
              }
          }

          & span {
              font-weight: 600;
              position: relative;
          }
      }

      & h2 {
          font-weight: 700;
          font-size: 16px;
          color: $secondary;
          margin-bottom: 10px;

          @media #{$desktop} {
              font-size: 22px;
          }
      }

      & h2+p {
          margin-bottom: 20px;
          text-align: left;

          @media #{$desktop} {
              margin-bottom: 0;
              text-align: left;
          }
      }

      & p {
          font-size: 16px;
          font-weight: 400;
          color: $dark;
          text-align: left;

          @media #{$desktop} {
              font-size: 16px;
              text-align: right;
          }

          & a {
              color: $secondary !important;
              font-weight: 700;
              text-decoration: none;
              cursor: pointer;

              &:hover {
                  color: $secondary !important;
                  opacity: .85;
              }
          }
      }

      & .button {
          padding-left: 30px;
          padding-right: 30px;
          display: block;
          margin: 0 auto;
          border-radius: 10px;
          letter-spacing: .2px;

          @media #{$desktop} {
              padding-left: 20px;
              padding-right: 20px;
              font-size: 22px;
              display: inline-table;

          }
      }



  }

  & .simpleTabs {
      padding-left: 0;

      @media #{$desktop} {
          padding-left: 20px;
      }
  }

  & .searchWrap {
      position: relative;
      margin-bottom: 15px;

      @media #{$desktop} {
          margin-bottom: 0;
      }

      & input {
          padding: 9px 16px;
          border-radius: 10px;
          border: 1px solid #E5E7F3;
          font-size: 16px;
          font-weight: 400;
          color: $dark;
          width: 100%;
          background-color: #E5E7F3;
          padding-left: 40px;
          margin-bottom: 0;

          @media #{$desktop} {
              width: auto;
              padding: 9px 20px;
              padding-left: 40px;
          }
      }

      & b {
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          color: $primary;
          font-size: 18px;
      }
  }

  & .faqList {
      padding: 20px 0;
  }

  & .faqBox {
      padding: 15px 15px;
      background-color: $light;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-top: 15px;

      @media #{$desktop} {
          padding: 20px 26px;
          border-radius: 20px;
      }

      &:first-child {
          margin-top: 0;
      }

      &-head {
          position: relative;
          cursor: pointer;

          & h3 {
              text-align: left;
              font-size: 16px;
              font-weight: 700;
              color: $primary;
              margin: 0;
              padding-right: 45px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media #{$desktop} {
                  font-size: 20px;
                  padding-right: 55px;

              }
          }

          & b {
              position: absolute;
              right: -15px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 26px;
              color: $red;
              font-weight: bold;
              width: 55px;
              height: calc(100% + 30px);
              background-color: $secondary;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              @media #{$desktop} {
                  font-size: 42px;
                  right: -26px;
                  width: 74px;
                  height: 65px;
                  border-radius: 20px;

              }
              &::before{
                  display: none;
              }
              &::after{
                  content: '';
                  background: url(../../img/icons/arrowdown.png);
                  background-repeat: no-repeat;
                  background-size: contain;
                  width: 26px;
                  height: 17px;
                  @media #{$desktop} {
                      width: 45px;
                      height: 27px;
                  }
              }
          }
      }

      &-body {
          font-size: 14px;
          font-weight: 400;
          color: $dark-400;
          padding-top: 15px;
          display: none;

          @media #{$desktop} {
              font-size: 16px;
              padding-top: 22px;
          }

          & p {
              text-align: left;
              padding-bottom: 15px;
              text-align: justify;

              @media #{$desktop} {
                  padding-bottom: 20px;
              }

              &:last-child {
                  padding-bottom: 10px;
              }
          }
      }

      &.active {

          & .faqBox-head {
              & b {
                  transform: translateY(-50%) rotate(180deg);
              }
              & h3{
                  overflow: visible;
                  white-space: initial;
              }
          }

          & .faqBox-body {
              //  display: block;
          }
      }
  }
}
.authorFaqBox {
  margin-top: 83px;
  background: #ffffff;
  padding-bottom: 140px;
  & .faqSection {
      padding-top: 50px;
      background: #ffffff;
      & .faqBox {
          border: 1px solid #167092;
          &.active{
              & .faqBox-body {
                  display: block;
              }
          }
      }
      & .faqBox-head {
          position: relative;
          & .slidePage {
              position: absolute;
              top: -120px;
          }
          & h3 {
              color: #FF8300;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 18px;
          }        
          b{
          background: transparent;

          &:after {
              background: url(../../img/icons/faqtabs.svg);
              width: 44px;
              height: 39px;
          }

      }
          
      }
      & .containWrap {
          max-width: 1440px;
      }
  }
  & .faqTabs {
      width: 382px;
      display: inline-block;
      vertical-align: top;
      border: 1px solid #167092;
      padding: 24px;
      border-radius: 20px;
      margin-right: 40px;
      position:sticky;
      top: 90px;
      & h3 {
          color: #FF8300; 
          font-size: 16px; 
          font-weight: 600; 
      }
      & a {
          color: #167092;
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          margin: 10px 0;
          display: block;
          position: relative;
          &.active {
              color: #404040;
              &:after{
                  content: "";
                  width: 34px;
                  height: 8px;
                  border-radius: 8px;
                  background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                  display: block;
                  position: absolute;
                  left: 0;
                  bottom: -7px;
                  z-index: 1;
              }
          }
      }
  }
  & .faqContent {
      width: calc(100% - 430px);
      display: inline-block;

      & .faqBox-body {
          & ul {
              padding-left: 20px;
          }
      }
  }
}

.similartyText {
  color: #BF1E2E; 
  font-size: 14px; 
  font-weight: 700; 
  text-transform: uppercase;
  & span{
    color: #BF1E2E !important;
    margin-right: 7px;
  }
  & b {
    cursor: pointer;
  }
}

.amendmentPopup {
  max-height: 70vh;
  overflow: auto;
  padding: 0 12px;
  & .row { 
    margin-bottom: 15px;
    position: relative;
    &::before{
      content: '';
      width: 96%;
      height: 1px;
      background: #97979B;
      position: absolute;
      left: 2%;
      bottom: 0;

    }
  }
  & .pageHeading {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
  }
  & .fieldWrap {
    margin-bottom: 20px;
    & .fieldForm {
      min-height: 40px;
    }
  }
}
.imp-info {
  padding: 0 20px;

  p {
      text-transform: uppercase;
      font-size: 18px;
      font-family: 'Inter';
      color: #167092;
      font-weight: 700;
  }
}
.refundable-price {
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;
  color: #BF1E2E;
  font-style: italic;
}
.postReviewI {
  padding-left: 20px;
  font-style: italic;
}


.blinkWrap {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes blinker {
  from {
    opacity: 1.0;
  }
  to {
    opacity: 0.0;
  }
}

.launchWrap { 
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  position: relative;
  
  
    .launch-offer{
        
    }
  & .customTool {
      & img.pe-cursor{
          width: 112px;
          opacity: 0;
          height: 24px;
      }
      &::before{
          content: 'Know More';
          position: absolute;
          right: 12px;
          color: #009B94;
          font-weight: 600;
          text-transform: uppercase;
      }
   
  }
}

.right_180{
  right: 180px !important;
}
.closebtnds{
  width: 20px;
  display: block;
  height: 20px;
  font-size: 10px;
  opacity: 1;
  position: absolute;
  cursor: pointer;
  font-weight: 600;
  right: -5px;
  top: -5px;
}
/* Safari syntax */
.publiceUiBox:-webkit-full-screen {
  background-color: white;
  padding: 50px;
  overflow: auto;
}

/* IE11 syntax */
.publiceUiBox:-ms-fullscreen {
  background-color: white;
  padding: 50px;
  overflow: auto;
}

/* Standard syntax */
.publiceUiBox:fullscreen {
  background-color: white;
  padding: 50px;
  overflow: auto;
}
.previewBox .contentPreview.References p {
  display: list-item;
  list-style: auto;
  margin-bottom: 25px;
  margin-left: 20px;
}
.previewBox{
  .contentPreview.References p{
      &.dshighlight{
          background-color: #ff830040;
      }
      margin-bottom: 25px;
      display: list-item;
      list-style: auto;
      margin-left: 20px;
      span:hover{
          color: #FF8300 !important;
      }
      a:hover{
          color: #FF8300;
      }
  }
}
.tableres{
 overflow-x:auto;
 margin-bottom: 20px;
}
.tableres::-webkit-scrollbar {
  width: 7px;height: 5px;
  background-color: #f2f2f2;
}

.tableres::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
.tableres::-webkit-scrollbar-thumb {
  background:#7d7d7d; 
  border-radius: 10px;
}

.tableres::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d; 
}
/*======================*/
.journalShadow {
  .ag_menu {
    ul{
      justify-content: space-between;
    }
    li{
        margin: 0px 0px !important; 
        font-family: "Montserrat", sans-serif !important;
      &:before{
        content: none;
      }
      a{
        &.custom-journal{
          display: flex;
          font-size: 18px !important;
        }       
        .colr {
         font-weight: 600;
            color: #336d91;
            margin-left: 12px;
        }
        display: flex;
        padding: 30px 0px;
        margin: 0px;
        text-transform: uppercase;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        position: relative;
        text-decoration: none;
      }
    }
  }
}
.custom-journalist-class {
    padding: 40px 20px;
    font-family: "Montserrat", sans-serif;
    background: transparent !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.160784313);
    ul{
      list-style: none;
      li {
        padding: 10px 4px 0px 30px;
        font-size: 24px;
        a {
          color: #000;
          font-size: 20px;
          position: relative;
          border-bottom: 2px solid transparent;
          padding-bottom: 5px;
          text-decoration: none;
          &.active {
            border-bottom: 2px solid #fe8300;
            color: #336d91;
            font-weight: 600;
          }
        }
      }
    }
    .ag_heading_wrap h2, .ag_heading_center_wrap{
      display: flex;
      width: max-content;
      max-width: 100%;
      margin: 0 auto;
      flex-direction: column;
      margin-bottom: 40px;
      position: relative;
      z-index: 1;
      h2 {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 0;
      color: #336d91;
      display: flex;
      flex-direction: column;
      &:after{
        content: "";
        position: absolute;
        background-color: #fe8300;
        height: 3px;
        width: 180px;
        left: 0;
        bottom: -15px;
      }
    }
  }
}
.society-main {
  padding: 0 20px;
  span {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;
    color: #336d91;
    position: relative;
    margin-right: 60px;
    font-weight: 600;
    &::before {
      content: "";
      transform: translateX(-50%);
      left: 50%;
      bottom: -15px;
      width: 149px;
      position: absolute;
      background-color: #fe8300;
      height: 2.5px;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: #707070;
      height: 40px;
      width: 2px;
      right: -30px;
      top: -3px;
    }
  }
  .btn-submit {
    border: none;
    color: #ffffff;
    background-color: #bc1c2b;
    border-radius: 10px;
    font-size: 20px;
    padding: 0px 25px !important;
    text-transform: capitalize;
    min-width: 160px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    &:hover {
      background-color: #336d91;
    }
  }
  .main-headings {
  h3{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    line-height: 30px;
    margin-bottom: 25px;
    color: rgb(51, 109, 145);
    position: relative;
    margin-right: 60px;
    font-weight: 600;
    margin-top: 25px;
    &::before {
      content: "";
      bottom: -10px;
      width: 149px;
      position: absolute;
      background-color: #fe8300;
      height: 2.5px;
    }
  }
    div{
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      line-height: 1.6;
      -webkit-font-smoothing: antialiased;
      font-size: 20px;
      font-weight: 430;
      margin: 0;
      color: #000000;
      
    }
    .div1 {
      border-radius: 20px;
      min-height: 200px;
      border: 1px solid rgba(214, 214, 214, 0.46);
    }
  }
}
.main-headings1 {
  h1 {
    font-size: 48px !important;
    line-height: 40px;
    margin-bottom: 0;
    color: #336d91;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
    margin: 50px 0;
    &::after {
      content: "";
      position: absolute;
      background-color: #fe8300;
      height: 3px;
      width: 180px;
      left: 0;
      bottom: -30px;
      right: 0;
      margin: 0 auto;
    }
  }
}
.main-col1 {
  .card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 15px;
    padding: 25px;
    text-align: center; 
    padding-bottom: 90px;
    margin-bottom: 40px;

    h4 {
      background-color: #bc1c2b;
      font-size: 24px;
      text-align: center;
      color: #ffffff;
      padding: 10px;
      border-radius: 0 40px;
      margin-top: -21px;
      z-index: 1;
      position: relative;
      font-weight: 600;
    }
    p {
      font-size: 18px;
      color: #000000;
      text-align: left;
    }
    a {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);  
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
      color: #000000;
      text-align: left;
    }
  }
  .col-md-4 {
    padding: 0 35px;
  }
}
.doisavebtn{
  position: absolute;
  right: 7px;
  top: 26%;
  font-size: 15px;
  border-radius: 5.5px;
  padding: 3.5px;
  text-transform: uppercase;
}