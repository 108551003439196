.button {
    padding: 8px 14px;
    font-size: 16px;
    margin: 0;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    border-radius: 10px;
    min-width: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    color: $primary;
    height: auto;
    box-shadow: none;
    outline: none;
    transition: all 350ms ease-in-out;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        opacity: .85;
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: .35;
    }

    &-sm {
        padding: 4px 12px !important;
        font-size: 14px !important;
        border-radius: 10px !important;
    }

    &-lg {
        padding: 10px 18px !important;
        font-size: 18px !important;
        border-radius: 10px !important;
    }

    & b[class^=icon-] {
        font-size: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        font-weight: normal;
        margin-top: 0px;
    }

    &-primary {
        color: $light !important;
        background-color: $primary;

        &:hover,
        &:active {
            color: $light !important;
            background-color: $primary !important;
        }
    }

    &-outline {
        background-color: rgba($primary, .12);
        border: 1px dashed $primary;
    }

    &-white {
        background: $light;
        color: $secondary;

        &:hover {
            background: $secondary;
            color: $light;
        }
    }

    &-ntv {
        color: $dark-900;
    }

    &-outline-ntv {
        background-color: rgba($primary, 0);
        border: 1px solid #404040;
        color: #404040;
        &:hover{
            border: 1px solid #000000;
            color: #000000; 
        }
    }

    &-outline-primary {
        background-color: rgba($primary, 0);
        border: 1px solid $primary;
        color: $primary;
    }

    &-blue {
        color: $light !important;
        background-color: $blue-dark;

        &:hover,
        &:active {
            color: $light !important;
            background-color: $blue-dark !important;
        }
    }

}