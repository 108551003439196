/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/***/
.pageMenu {
    padding: 0 25px 60px 25px;
  
    & .menuToggle {
      border-bottom: 1px solid rgba($dark-cc, 0.5);
      padding-top: 20px;
      padding-bottom: 20px;
  
      &-head {
        cursor: pointer;
        position: relative;
  
        & h2 {
          font-size: 18px;
          font-weight: 600;
          color: $highlight;
          margin: 0;
        }
  
        &:hover {
          opacity: 0.85;
        }
      }
  
      &-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        color: $highlight;
        width: 12px;
        height: 12px;
        font-size: 60%;
  
        & b {
          display: block;
        }
      }
  
      &-body {
        padding-top: 10px;
        display: none;
      }
  
      &.active {
        & .menuToggle-head {
          & .menuToggle-icon {
            transform: translateY(-50%) rotate(180deg);
          }
        }
  
        & .menuToggle-body {
          display: block;
        }
      }
    }
  
    & .logout-link {
      padding-top: 20px;
  
      & a {
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        position: relative;
        color: $dark-400 !important;
  
        &:hover {
          opacity: 0.85;
        }
  
        & b {
          display: inline-block;
          vertical-align: middle;
          margin-top: -1px;
          margin-right: 4px;
        }
      }
    }
  
    &-link {
      padding: 0;
  
      & .onlineStatus {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $dark;
        font-weight: 400;
      }
  
      & .walletStatus {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $dark;
        font-weight: 400;
  
        & b {
          width: 18px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
  
          & img {
            display: block;
            margin: 0;
          }
        }
      }
  
      & li {
        padding: 9px 0;
        list-style: none;
  
        &:first-child {
          padding-top: 0;
        }
  
        &:last-child {
          padding-bottom: 0;
        }
  
        & a {
          font-size: 16px;
          font-weight: 600;
          color: $primary !important;
          text-decoration: none;
          cursor: pointer;
          position: relative;
          display: block;
  
          &:hover {
            opacity: 0.85;
          }
  
          &.active {
            font-weight: 700;
            padding-bottom: 8px;
            color: $dark-400 !important;
  
            &:hover {
              opacity: 1;
              cursor: default;
            }
  
            &:after {
              content: "";
              width: 34px;
              height: 8px;
              border-radius: 8px;
              // background: linear-gradient(270deg, rgba(1, 183, 174, 0.84) 27.38%, rgba(45, 51, 141, 0.84) 154.29%);
              background: linear-gradient(
                270deg,
                rgba(255, 176, 27, 0.84) -2.89%,
                rgba(227, 232, 123, 0.84) 100%
              );
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 1;
            }
          }
  
          & .menuToggle-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $primary;
          }
  
          &.show {
            & .menuToggle-icon {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
  
        & ul {
          padding: 15px 20px;
          margin: 10px -10px;
          margin-bottom: 0;
          background-color: #f4f4f9;
          border-radius: 10px;
        }
        & .red-dot {
          width: 8px;
          height: 8px;
          &.withcount{
            width: 15px;
            height: 15px;
            font-style: normal;
            color: #ffff;
            display: flex;
            right: 30px;
            justify-content: center;
            font-size: 12px;
          }
        }
      }
    }
  }
  