body {
    position: relative;
}
$color1: #167092;
$color2: #FF8300;
$color3: #009B94;
$color_gray: #404040;
$color_black: #000000;

.containWrap {
    max-width: 1204px;
    margin: 0 auto;
    clear: both;
    padding: 0 35px;
}

.centerSection {
    padding-top: 92px;
}

/** PAGE SETUP **/
.pageWrap {
    padding: 0 !important;
    display: flex;
    justify-content: end;
    position: relative;
    background-color: $light;

    & .pageLeft {
        background-color: #F9F9FD;
        border-radius: 0 20px 20px 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 230px;
        min-height: 100%;
        padding-top: 0; 

        & .copyRight {
            position: absolute;
            left: 25px;
            bottom: 20px;
            font-size: 10px;
            font-weight: 400;
            color: $dark;
        }
    }

    & .pageRight {
        width: calc(100% - 230px);
        min-height: 576px;
        padding: 30px 55px 52px 55px;
        overflow: hidden;
    }
}

/** PAGE HEADINGS **/
.pageTitle {
    font-size: 18px;
    font-weight: 400;
    color: $dark-400;
    line-height: 20px;
    margin: 0;
}

.pageHeading {
    font-size: 20px;
    font-weight: 700;
    color: $highlight;
    line-height: 24px;
    margin: 0;
}

.pageSubHeading {
    font-size: 18px;
    font-weight: 700;
    color: $highlight;
    margin: 0;
    &.smHeading{
        font-size: 16px;
    }
}

.pageCrumb {
    display: flex;
    align-items: center;

    & .goBack {
        color: $primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-decoration: none !important;
        padding-bottom: 12px;
        cursor: pointer;
        position: relative;

        & b {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 38px;
            line-height: 12px;
        }
    }

    & h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        color: $highlight;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid $dark-100;
    }
}

.pageOption {
    display: inline-flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;

    &-link {
        font-size: 16px;
        font-weight: 700;
        color: $primary;
        text-decoration: none !important;
        cursor: pointer;
        margin-bottom: 5px;
    }

    &-change {
        max-width: 180px;
        width: 100%;
        display: inline-block;
        text-align: left;
        vertical-align: middle;

        & .labelForm {
            font-size: 14px;
        }

        & .reactSelect {
            &__single-value {
                color: $primary !important;
            }
        }

        & .fieldForm {
            border-color: $primary;
            color: $primary;
            font-size: 16px;
            font-weight: 700;
        }
    }

}

.searchGobal {
    max-width: 400px;

    & .fieldIcon {
        &_pre {
            color: $blue-dark;
        }

        & .fieldForm {
            height: 36px;
            background-color: rgba($blue-dark, .04);
            border-color: rgba($blue-dark, .2);
        }
    }
}

.labelHeading {
    font-size: 18px;
    font-weight: 700;
    color: $highlight;
    line-height: 22px;
    margin: 0;
    padding: 0 10px;
    padding-bottom: 25px;

}

.listTitle {
    font-size: 14px;
    font-weight: 400;
    color: $dark-400;
    line-height: 20px;
    margin: 0;
    padding-bottom: 5px;
}

/****/
.page {
    &-100 {
        display: flex;
    }

    &-70 {
        width: 70%;
        padding-right: 15px; 
    }

    &-30 {
        width: 30%;
        padding-left: 15px;

    }
}

/****/
.pageAlert {
    background-color: rgba($secondary, 1);
    border-radius: 10px;
    padding: 8px 16px;
    color: $light;

    & h2 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
    }

    & p {
        font-size: 12px;
        opacity: .95;
        font-weight: 300;
    }
}

/****/
.reportBox {
    background-color: rgba($highlight, .06);
    padding: 12px 25px;
    border-radius: 10px;
    max-width: 560px;
    display: inline-table;
    width: 100%;
    border: 1px solid rgba($highlight, .4);

    &.limit {
        max-width: 360px;
    }

    & ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & li {
            list-style: none;
            padding: 0 15px;
            //border-right: 1px solid rgba($dark, .2);
            //width: 33.3%;
            text-align: center;

            &:last-child {
                padding-right: 0;
                border-right: none;
            }

            &:first-child {
                padding-left: 0;

                & small {
                    font-weight: 700;
                }
            }

            & small {
                display: block;
                font-size: 14px;
                color: $dark-400;
                font-weight: 600;
                line-height: 16px;
                margin-bottom: 0;
                white-space: nowrap;
            }

            & p {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: .5px;
                color: $highlight;
                font-weight: 600;
            }
        }
    }
}

/****/
.download-link {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $light !important;
    text-decoration: none;
}

.download-link+small {
    display: block;
    font-size: 12px;
    font-weight: 500;

}

.viewLink {
    font-weight: 700;
    color: $primary !important;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.minMax {
    display: flex;
    align-items: center;
    justify-content: center;

    &-col {
        width: 80px;
        text-align: center;

        & label {
            font-size: 12px;
            color: $dark-400;
        }

        & input {
            padding: 1px 15px;
            text-align: center;
            border-radius: 10px;
            border: 1px solid rgba($dark-900, .65);
            font-weight: 600;
            width: 100%;
            font-size: 14px;

            &:disabled,
            &[disabled] {
                background-color: rgba($dark, .04);
                border-color: rgba($dark, 0) !important;
                color: $dark;
            }
        }
    }

    &-dash {
        padding: 0 10px;
        padding-top: 22px;
        font-size: 14px;
        color: $dark;
    }

    &-post {
        padding: 0 5px;
        padding-top: 22px;
        font-size: 12px;
        color: $dark-400;
    }
}

.simpleTabs {
    margin-bottom: 0;

    & ul {
        display: flex;
        align-items: flex-end;
        border-bottom: 2px solid rgba($dark-900, .25);

        & li {
            display: flex;
            list-style: none;
            padding: 0 15px;
            padding-bottom: 10px;
            //border-bottom: 1px solid rgba($dark-900, .1);
            position: relative;

            &:first-child {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }

            &:last-child {
                padding-right: 0;
            }

            &:before {
                content: "";
                width: 1px;
                height: 14px;
                background-color: rgba($dark-ad, 1);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                margin-top: -5px;
            }

            & a {
                font-size: 18px;
                font-weight: 700;
                color: $primary !important;
                text-align: center;
                text-decoration: none;
                position: relative;

                &:hover {
                    opacity: .85;
                    cursor: pointer;
                }

                &.active {
                    color: $dark-400 !important;

                    &:hover {
                        opacity: 1;
                        cursor: default;
                    }

                    &:after {
                        content: "";
                        width: 34px;
                        height: 8px;
                        border-radius: 8px;
                        background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -14px;
                        z-index: 1;
                    }
                }
            }
        }
    }

}

.emptyBox {
    display: block;
    margin: 0 auto;
    align-self: center;
    text-align: center;
    max-width: 280px;
    padding: 50px 0;

    &-icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        padding: 5px;
        background-color: rgba($dark-900, .2);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
    }

    &-title {
        font-size: 30px;
        font-weight: 700;
        margin: 0;
    }

    &-text {
        font-size: 18px;
        padding: 20px 0;
        padding-top: 15px;
    }
}

.noArticle {
    display: block;
    margin: 0 auto;
    align-self: center;
    text-align: center;
    max-width: 320px;
    padding: 50px 0;

    &-icon {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        padding: 5px;
        background-color: rgba($secondary, .1);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }

    &-title {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        color: $secondary;
    }

    &-text {
        font-size: 18px;
        padding: 5px 0;
    }

    &-subtext {
        font-size: 14px;
        opacity: .75;
    }
}

.noMessage {
    border-radius: 16px;
    background-color: rgba($dark, .02);
    padding: 50px 40px;
    color: $dark-400;
    font-size: 16px;
    text-align: center;
}

/** PAGE MSG **/
.pageMsg {
    border-radius: 10px;
    padding: 10px 20px;
    background-color: $dark;
    color: $light;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: .5px;
    margin-bottom: 20px;
    font-family: $familySecondary;
    margin-top: 22px;

    & span {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        background-color: $primary;
        margin: 0 4px;
        margin-top: -2px;
    }
}

/** PAGE COVER **/
.boxCover {
    padding: 45px 55px;
    background: $light;
    box-shadow: 0px 0px 14px rgba($dark, 0.09);
    border-radius: 20px;
    margin-top: 10px;

    &.noStyle {
        background: none;
        box-shadow: none;
    }

    & .titleText {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 5px;
        color: rgba($highlight, 1);

        & .uploadLink {
            font-size: 14px;
            text-decoration: none;
            cursor: pointer;

            & b {
                margin-right: 5px;
                vertical-align: middle;
                margin-top: -2px;
                display: inline-block;
            }

            &:hover {
                color: $primary;
                opacity: .85;
            }
        }
    }

    & .boxTitle {
        color: $dark;
        margin-bottom: 35px;

        & p {
            font-size: 14px;
            font-weight: 400;
            color: $dark-400;
        }
    }
}

.infoMark {
    width: 16px;
    height: 16px;
    background-color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $light;
    font-size: 12px;
    text-decoration: none;
    border-radius: 50%;
    margin: 0 5px;
    font-family: $familySecondary, $fallbackfont;
    vertical-align: middle;

    &:hover {
        color: $light;
        opacity: .85;
    }
}
.manageEditorInner {
    & .backFooterBox {
        margin-top: 0;
        margin-bottom: 50px;
    }
    & .form-control {
        border-radius: 10px;
        font-size: 16px;
        color: #009B94;
        font-weight: 600 !important;
    }
    & .form-control::placeholder{
        font-weight: 500 !important; 
        font-size: 16px !important; 
        color: $color_gray !important;
    }
    & .fieldWrap .labelFormTwo {
        margin-bottom: 20px !important;
    }

}
.editorWrap {
    & .sun-editor {
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 10px;
        overflow: hidden;
        & .se-toolbar {
            border-radius: 10px 10px 0 0 !important;
            background-color: rgba(0,155,148,0.08);
        }
        & button{
            & .txt{
               color: #167092;
               font-size: 16px;
                font-weight: bold;
                text-transform: capitalize;
            }
            
        } 
        & .se-btn-select.se-btn-tool-font {
            width: 65px;
        }
        & .se-btn-select.se-btn-tool-size {
            width: 65px;
        }
        & .se-btn {
            color: #167092;
            font-size: 16px;
        }
        & .se-resizing-bar {
            background-color: rgba(0,155,148,0.08);
            & .se-navigation {
                opacity: 0;
            }
        }
        &.se-resizing-bar .se-char-counter-wrapper .se-char-label {
            font-family: "Inter", sans-serif;
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
            color: #009b94;
        }
        & .se-resizing-bar .se-char-counter-wrapper .se-char-counter {
            font-family: "Inter", sans-serif; 
            font-size: 14px;
            color: #009b94;
        }
    }
      
}

.ag_opportunities_wrap .col-xl-4 {
    padding: 0 35px;
  }
  
  .ag_opportunities_box {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 25px;
    text-align: center;
  }
  
  .ag_opportunities_box img {
    width: 100%;
  }
  
  .ag_opportunities_box h3 {
    background-color: #bc1c2b;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    padding: 10px;
    border-radius: 0 40px;
    margin-top: -21px;
    z-index: 1;
    position: relative;
    font-weight: 600;
  }
  
  .ag_opportunities_box h4 {
    color: #336d91;
    font-size: 24px;
    text-align: center;
    margin: 15px 0 10px 0;
  }
  
  .ag_opportunities_box p {
    text-align: left;
  }
  
  .ag_opportunities_box a {
    font-weight: 600;
    text-decoration: none;
  }
// .modal-backdrop.show{
//     display: block !important;
// }
.sun-editor .se-resizing-bar .se-char-counter-wrapper {
    margin-right: 15px !important;
}
.sun-editor .se-toolbar {
    border-radius: 20px 20px 0px 0px !important;
}
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text,
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut { color: #ffffff !important; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
    position: absolute !important;
     left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100% !important;
     border: 3px solid #167092 !important;
     background-color: #fff !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.09) !important;
    border-radius: 20px !important;
    overflow: hidden;
}
.editorWrap .sun-editor .se-dialog-header .se-btn {     color: #000;  text-decoration: none;
    opacity: 0.75; }
    .sun-editor .se-dialog-tabs button:last-child{ display: none; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-radio { width: 14px; height: 14px; }

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title,
.sun-editor .se-dialog .se-dialog-inner {     font-family: "Inter", sans-serif !important;
    font-size: 16px !important;}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title { text-transform: capitalize; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {padding: 10px 15px 0 4px;}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer .se-btn-primary{ color: #fff !important; background-color: #167092; padding: 10px 15px; font-size: 16px; font-weight: 700 !important; border-radius: 10px; border: 0 !important; border-radius: 10px !important; }
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer .se-btn-primary span { color: #ffffff !important; font-size: 16px !important; font-weight: 700 !important; font-family: "Inter", sans-serif !important; }
.confirmFooter {
    background: #FFFFFF;
    border: 0.5px solid $color1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    max-width: 1440px;
    &.confirmFooterFix {
        position: absolute;
    }
    & .btn-link-active {
        margin-right: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #167092;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
    } 
    & .confirmContent {
        & h3 {
            font-weight: 700;
            font-size: 18px;
            color: #009B94;
            text-transform: uppercase;
            & b {
                // background: rgba(241, 97, 54, 0.05);
                // border: 0.5px solid $color2;
                border-radius: 5px;
                padding: 3px 0px;
                color: $color2;
                font-weight: 700;
            }
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $color_black;
        }
        & small {
            color: #BF1E2E;
        }
        & .questionBox {
            & span {
                color: #000000;
                font-weight: 400;
            }
        }
    }
    & .confirmBtn {
        display: inline-flex;
        align-items: center;
    }

}
.modal-backdrop.show{
    display: block !important;
}
.submissionPopup {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    text-align: left;
    padding: 0 15px;
    & strong {
        font-weight: 500;
        display: block;
        margin-bottom: 16px;
    }
   & p{
    font-family: $familySecondary, $fallbackfont;
    margin-bottom: 16px;
     
   }
   
}
.btn-link-active {
    margin-right: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #167092;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
} 
.offensiveBox{
    border-radius: 10px;
    background: rgba(0, 155, 148, 0.04);
    text-align: left;
    padding: 8px 20px;
    margin: 16px auto 0;
    width: 92%;
    & ol {
        text-align: left;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
        margin-left: 20px;
        & li { 
            font-weight: 600;
            font-size: 18px;
            line-height: 29px;
            color: #009B94;
            text-transform: capitalize;
        }
    }
}
.helpPopupText { 
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    border-top: 0.5px solid #A7A7A7;
    padding-top: 17px;
    margin-top: 10px;
    width:100%;
    &.paymentTile {
        & span {
            color: #167092;
            font-weight: 700;
        }
    }
    & a {
       color: #167092;
       font-weight: 700;
       padding-left: 6px; 
    }
    & .helpPopupList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style-type: none;
        max-width: 450px;
        margin: 0 auto;
        & a:hover {
            color: #167092;
        }
        & li {
            &:first-child{
                & a {
                    &::before {
                        display: none;
                    }
                }
            } 
            & a {
                padding-left: 10px;
                position: relative;
                text-decoration: none;
                &:first-child{
                    padding-left: 0;
                }
                &::before{
                    content: '';
                    width: 6px;
                    height: 6px;
                    background: #FF8300;
                    border-radius: 4px;
                    position: absolute;
                    left: -16px;
                    top: 50%;
                    transform: translateY(-50%); 
                }
            }
        }
    }
}
.congratulationsPopup {
    text-align: left;
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    // font-family: $familySecondary, $fallbackfont;
    & h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #009B94;
        text-align: center; 
    }
    & a{
        text-decoration: none;
        font-weight: 700;
    }
    & p {
        margin: 20px 0;
        color: #000000;
        font-family: $familySecondary, $fallbackfont; 
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 10px;
        }
    }
}