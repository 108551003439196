/** VAR **/
@import "../../../../public/assets/scss/base/themecomponents/_variables_app.scss";

.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #F7F9FD;
  background-image: url(../../../../public/assets/img/banner/banner_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.loginWrap {
  display: block;
  width: 540px;
  padding: 20px 64px;
  margin: 0 auto;
  background: $light;
  border-radius: 30px;
  box-shadow: 0px 0px 48px rgba(0, 155, 148, 0.14);

  &-logo {
    margin: 0 auto;
    width: 410px;
    margin-bottom: 20px;
    text-align: center;
  }

  &-title {
    text-align: center;

    & h1 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $blue-dark;
      margin: 0 auto;
      margin-bottom: 10px;
      letter-spacing: -.2px;
    }

    & p {
      line-height: 22px;
      font-size: 16px;
      font-family: $familySecondary;
      color: $dark;
    }
  }

  &-form {
    padding: 50px 0;
    padding-bottom: 25px;

    & .text-link {
      font-weight: 700;
      font-size: 18px;
    }
  }

  &-submit {
    & .button {
      height: 44px;
      max-width: 236px;
      width: 100%;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      vertical-align: middle;
    }
  }

}

.forgotModal {
  & p {
    font-size: 14px;
    color: $dark;
    line-height: 20px;
  }

  & .fieldInnerWrap {
    text-align: left;
  }
}

.otpModal {

  & p {
    font-size: 14px;
    color: $dark;
    line-height: 20px;
  }

  & .fieldWrap {
    &>div:first-child {
      justify-content: center;
      margin-bottom: 5px;
    }

    & .boxStyle {
      width: 35px;
      height: 35px;
      margin: 0 10px;

      & input {
        width: 100% !important;
        height: 100%;
        margin: 0 auto;
        border-radius: 5px;
        border: 1px solid $dark-800;
        font-weight: 600;
        text-align: center;
        font-size: 14px;
        color: $blue-dark;
      }

      &.errorOTP {

        & input {
          border-color: $red;
        }
      }
    }

  }



}