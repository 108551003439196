/** VAR **/
@import "../base/themecomponents/_variables_app.scss";

/***/
.filterSet {
    padding: 12px 20px;
    padding-bottom: 15px;
    border-radius: 14px;
    position: relative;
    margin-bottom: 20px;

    &.active {
        background: $light;
        box-shadow: 0px 0px 14px rgba($dark, 0.06);
    }

    & .filterToggle {
        width: 150px;
        text-align: center;
        font-size: 16px;

        & a {
            color: $highlight;
            font-weight: 600;
            cursor: pointer;
            text-decoration: none !important;
        }
    }

    &-body {
        padding-right: 175px;
        margin-top: -25px;
    }

    &-head {
        font-size: 16px;
        padding-right: 44px;
        & a {
            color: #EF6209;
            & b {
                font-size: 60%;
                margin-top: -1px;
                margin-left: 4px;
                vertical-align: middle;
                display: none;
            }
        }
    }

    &-foot {
        position: absolute;
        right: 22px;
        bottom: 12px;
        font-size: 16px;

        & a {
            margin-left: 30px;
            position: relative;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                &:before {
                    content: "";
                    position: absolute;
                    left: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 14px;
                    background-color: rgba($dark-ad, 1);
                }
            }
        }
    }

    & .labelForm {
        font-size: 14px;
        line-height: normal;
        margin-bottom: 7px;
    }

    & input.fieldForm,
    & select.fieldForm,
    & textarea.fieldForm {
        background-color: rgba($blue-dark, .04);
        color: $blue-dark;
        border: 1px solid rgba($dark-900, 0);
        height: 36px;

        &:disabled,
        &[disabled] {
            background-color: rgba($blue-dark, .04);
            border-color: rgba($blue-dark, 0) !important;
            color: $blue-dark;
        }
    }
}